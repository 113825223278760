import React, { useState } from "react";
import * as Ant from "antd";
import { WarnLabelBlock, Font } from "./Widget";

const LIMIT = 30;

/**
 * 新增大量選項
 * @param {*} param0
 * @returns
 */
function AddManyModal({ visible, onCancel, title = "新增大量選項", ...props }) {
  const [value, setValue] = useState("");

  function onOK() {
    const textList = value.split("\n").filter(text => !!text);
    props.appendMultipleOptions(textList);
    setValue("");
    onCancel();
  }

  return (
    <Ant.Modal
      className="custom-modal"
      title={title}
      visible={visible}
      okText="確定"
      cancelText="取消"
      onOk={onOK}
      onCancel={onCancel}
      bodyStyle={{ padding: 0 }}
    >
      <WarnLabelBlock>
        利用複製貼上迅速新增大量選項。每個選項都應獨立顯示於一行。選項請勿輸入特殊符號。
      </WarnLabelBlock>
      <div style={{ padding: "16px 30px", backgroundColor: "#e0e0e0" }}>
        <Font.Body style={{ color: "rgba(0,0,0,0.45)" }}>
          一行文字為一個選項，會以新增選項方式帶入。
        </Font.Body>
        <Ant.Input.TextArea
          value={value}
          onKeyPress={e => {
            if (e.key === "Enter") {
              if (value.split("\n").filter(text => !!text).length >= LIMIT) {
                e.preventDefault();
              }
            }
          }}
          onChange={e => setValue(e.target.value)}
          style={{ height: 250 }}
        />
        <Font.Body style={{ color: "rgba(0,0,0,0.45)" }}>
          一次最多可以輸入 <span style={{ color: "#f759ab" }}>{LIMIT}</span>{" "}
          個選項。
        </Font.Body>
      </div>
    </Ant.Modal>
  );
}

export default AddManyModal;
