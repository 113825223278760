/**
 * review 原本的ApiAdapter調用起來太麻煩
 * 如果沒有綁很深的原有邏輯，可以使用這個方式
 *
 * 提供以下方法
 * apAxios
 * setAuthToken
 */
import _ from "lodash";
import constants from "../constants";
import axios from "axios";

const apAPIBaseUrl =
  process.env.NODE_ENV === "non-token"
    ? constants.apiUrl
    : constants.apiTokenUrl;
console.debug("apAxios:apAPIBaseUrl:", apAPIBaseUrl);
// 定義共用axios
const apAxios = axios.create({
  // baseURL: 'https://tiny-api.chatok.io/',
  baseURL: apAPIBaseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

// 設定AccessToken
const setAuthToken = token => {
  if (token) {
    apAxios.defaults.headers.common["Authorization"] = "Bearer " + token;
    localStorage.setItem("token", token);
  } else {
    delete apAxios.defaults.headers.common["Authorization"];
    localStorage.removeItem("token");
  }
};

export default apAxios;
export { setAuthToken };
