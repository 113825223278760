import React, { useCallback, useState } from "react";
import styled from "styled-components";
import isHotkey from "is-hotkey";
import { Editable, useSlate, useSelected, useFocused } from "slate-react";
import { Editor, Transforms } from "slate";
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  FormatListBulleted,
  FormatListNumbered,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatAlignJustify,
  FormatClear,
  Videocam,
  Image,
} from "styled-icons/material";
import { TextHeader1, TextHeader2 } from "styled-icons/fluentui-system-filled";
import { CardText } from "@styled-icons/bootstrap";
import * as Ant from "antd";
import deepCopy from "deepcopy";

import * as SvgIcon from "./SvgIcon";
import Widget, { FontSize, Color } from "./Widget";
import ColorPalette from "./Color/ColorPalette.js";
import VideoIframe from "./VideoIframe";

export const ALL_MARKS = [
  "bold",
  "code",
  "italic",
  "underline",
  "color",
  "size",
  "font",
  "strikethrough",
  "border",
  "highlight",
  "link",
  "header_one",
  "header_two",
  "privacycontent",
];

export const ALL_ALIGHMENT_BLOCKS = [
  "align-left",
  "align-right",
  "align-center",
  "align-justify",
  // "list-item",
];

export const ALL_LIST_STYLE = ["numbered-list", "bulleted-list"];

export function clearAllFormats(nodes, setContent) {
  nodes = deepCopy(nodes);

  function clearNode(node) {
    if (
      ALL_ALIGHMENT_BLOCKS.indexOf(node.type) > -1 ||
      node.type === "list-item"
    ) {
      node.type = "paragraph";
    }
    if (node.children && node.children.length > 0) {
      if (
        ALL_ALIGHMENT_BLOCKS.indexOf(node.type) > -1 ||
        node.type === "list-item"
      ) {
        node.type = "paragraph";
      }
      if (ALL_LIST_STYLE.indexOf(node.type) > -1) {
        delete node.type;
      }

      for (const n of node.children) {
        clearNode(n);
      }
    } else {
      for (const prop in node) {
        if (ALL_MARKS.indexOf(prop) > -1) {
          delete node[prop];
        }
      }
    }
  }

  for (const node of nodes) {
    clearNode(node);
  }

  setContent(nodes);
}

function clearMarks(editor) {
  ALL_MARKS.forEach(mark => {
    Editor.removeMark(editor, mark);
  });
}

function clearAlignmentBlocks(editor) {
  Transforms.unwrapNodes(editor, {
    match: n => ALL_ALIGHMENT_BLOCKS.includes(n.type),
    split: true,
  });
}

const Button = props => {
  return (
    <Ant.Button
      style={{
        padding: 0,
        backgroundColor: "transparent",
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: 32,
        minHeight: 32,
        marginRight: 3,
      }}
      {...props}
    >
      {props.children}
    </Ant.Button>
  );
};

const Icon = props => {
  return <div {...props}>{props.children}</div>;
};

const Toolbar = props => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        backgroundColor: "#5d5c69",
      }}
    >
      {props.children}
    </div>
  );
};

const CustomEditor = {
  insertImage(editor, { url }) {
    const text = { text: "" };
    const image = { type: "image", url, children: [text] };
    Transforms.insertNodes(editor, image);
    // workaround, let user can select pure text rather than the image block
    Transforms.insertNodes(editor, {
      type: "paragraph",
      children: [{ text: "" }],
    });
  },

  insertYoutubeVideo(editor, { videoId }) {
    const text = { text: "" };
    const video = {
      type: "youtube",
      url: `https://www.youtube.com/embed/${videoId}`,
      children: [text],
    };
    Transforms.insertNodes(editor, video);
    // workaround, let user can select pure text rather than the image block
    Transforms.insertNodes(editor, {
      type: "paragraph",
      children: [{ text: "" }],
    });
  },
};

function ClearFormatButton(props) {
  const editor = useSlate();

  return (
    <Button
      type="text"
      onMouseDown={event => {
        event.preventDefault();
        clearMarks(editor);
        clearAlignmentBlocks(editor);
      }}
    >
      <FormatClear size={FontSize.subTitle} color={"#aaa"} />
    </Button>
  );
}

function DbgButton(props) {
  const editor = useSlate();

  return (
    <div
      style={{ padding: 10, cursor: "pointer", color: "red" }}
      onClick={() => {}}
    >
      DBG
    </div>
  );
}

function FontSizeButton({ DEFAULT }) {
  const editor = useSlate();

  return (
    <div style={{ background: "#5d5c69", paddingRight: 10 }}>
      <select
        style={{
          background: "#5d5c69",
          color: "#fff",
          border: "none",
          paddingLeft: 16,
          paddingTop: 7,
          paddingBottom: 5,
          height: 32,
          minWidth: 88,
        }}
        value={getFontSizeValue(editor, DEFAULT)}
        onChange={e => setFontSizeValue(editor, e.target.value)}
      >
        {[12, 14, 16, 18, 20, 22, 24, 28, 32, 36, 48, 72].map(fontSize => (
          <option key={fontSize} value={fontSize.toString()}>
            {fontSize}
          </option>
        ))}
      </select>
    </div>
  );
}

function HighLightButton({ DEFAULT }) {
  const editor = useSlate();
  const [pickedHighLightColor, setPickedHighLightColor] = useState();
  const [visible, setVisible] = useState(false);

  const setColor = pickedHighLightColor => {
    if (editor._onBlurSelection) {
      Transforms.select(editor, editor._onBlurSelection);
    }
    setHighLightColorValue(editor, pickedHighLightColor);
    setPickedHighLightColor(pickedHighLightColor);
  };

  return (
    <PopoverRemovePadding>
      <Ant.Popover
        content={
          <ColorPalette
            setColor={setColor}
            color={pickedHighLightColor}
            setVisible={setVisible}
          />
        }
        getPopupContainer={triggerNode => triggerNode.parentNode}
        trigger="click"
        visible={visible}
        onVisibleChange={e => {
          setVisible(e);
        }}
        destroyTooltipOnHide={true}
      >
        <Button onClick={() => setVisible(true)}>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SvgIcon.BorderColor
              size={FontSize.subTitle}
              color={
                getHighLightColorValue(editor, DEFAULT) !== DEFAULT.color
                  ? "white"
                  : "#ccc"
              }
            />
          </label>
        </Button>
      </Ant.Popover>
    </PopoverRemovePadding>
  );
}

function FontColorButton({ DEFAULT }) {
  const editor = useSlate();
  const [pickedColor, setPickedColor] = useState();
  const [visible, setVisible] = useState(false);

  const setColor = pickedColor => {
    if (editor._onBlurSelection) {
      Transforms.select(editor, editor._onBlurSelection);
    }
    setFontColorValue(editor, pickedColor);
    setPickedColor(pickedColor);
  };

  return (
    <PopoverRemovePadding>
      <Ant.Popover
        content={
          <ColorPalette
            setColor={setColor}
            color={pickedColor}
            setVisible={setVisible}
            selectedColor={getFontColorValue(editor, DEFAULT)}
          />
        }
        getPopupContainer={triggerNode => triggerNode.parentNode}
        trigger="click"
        visible={visible}
        onVisibleChange={e => setVisible(e)}
        destroyTooltipOnHide={true}
      >
        <Button onClick={() => setVisible(true)}>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SvgIcon.FormatColorText
              size={FontSize.subTitle}
              color={
                getFontColorValue(editor, DEFAULT) !== DEFAULT.color
                  ? "white"
                  : "#ccc"
              }
            />
          </label>
        </Button>
      </Ant.Popover>
    </PopoverRemovePadding>
  );
}

function FontButton({ DEFAULT }) {
  const editor = useSlate();

  return (
    <div style={{ background: "#5d5c69", paddingRight: 12 }}>
      <select
        style={{
          background: "#5d5c69",
          color: "#fff",
          border: "none",
          paddingLeft: 16,
          paddingTop: 7,
          paddingBottom: 5,
          height: 32,
          minWidth: 104,
        }}
        value={getFontValue(editor, DEFAULT)}
        onChange={e => setFontValue(editor, e.target.value)}
      >
        {[
          { display: "微軟正黑體", value: "Microsoft JhengHei" },
          { display: "思源黑體", value: "Noto Sans TC" },
          { display: "新細明體", value: "PMingLiU" },
          // {display: "測試標楷體", value: "cursive"}
        ].map(font => (
          <option key={font.value} value={font.value}>
            {font.display}
          </option>
        ))}
      </select>
    </div>
  );
}

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
  "mod+l`": "link",
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];

function AddVideoButton(props) {
  return (
    <Button
      type="text"
      onMouseDown={event => {
        event.preventDefault();
        props.onAddVideoClick && props.onAddVideoClick();
      }}
    >
      <Videocam size={FontSize.subTitle} color={"#aaa"} />
    </Button>
  );
}

function AddImageButton(props) {
  return (
    <Button
      type="text"
      onMouseDown={event => {
        event.preventDefault();
        props.onAddImageClick && props.onAddImageClick();
      }}
    >
      <Image size={FontSize.subTitle} color={"#aaa"} />
    </Button>
  );
}

function BasicToolbar(props) {
  return (
    <div
      style={{
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Toolbar>
        <div
          style={{
            paddingRight: 10,
            paddingLeft: 10,
            cursor: "pointer",
            color: Color.GreyWhite,
            fontSize: FontSize.caption,
          }}
          onClick={props.onMoreClick}
        >
          進階文字編輯
        </div>
        <AddImageButton {...props} />
        <AddVideoButton {...props} />
      </Toolbar>
    </div>
  );
}

function AdvancedToolbar({ showPrivacyTool, DEFAULT }) {
  if (showPrivacyTool === undefined) showPrivacyTool = false;
  return (
    <Toolbar>
      <Widget.FlexRow>
        {showPrivacyTool ? (
          <MarkButton format="header_one" IconComp={TextHeader1} />
        ) : (
          undefined
        )}
        {showPrivacyTool ? (
          <MarkButton format="header_two" IconComp={TextHeader2} />
        ) : (
          undefined
        )}
        {showPrivacyTool ? (
          <MarkButton format="privacycontent" IconComp={CardText} />
        ) : (
          undefined
        )}
        <MarkButton format="bold" IconComp={FormatBold} />
        <MarkButton format="italic" IconComp={FormatItalic} />
        <MarkButton format="underline" IconComp={FormatUnderlined} />
        <MarkButton format="border" IconComp={SvgIcon.FontDownload} />
        <MarkButton format="strikethrough" IconComp={SvgIcon.Strikethrough} />
        <MarkButton format="link" IconComp={SvgIcon.Link} />
        <FontColorButton DEFAULT={DEFAULT} />
        <HighLightButton DEFAULT={DEFAULT} />
        {/* <MarkButton format="highlight" IconComp={SvgIcon.BorderColor} /> */}
      </Widget.FlexRow>

      <Widget.FlexRow>
        <FontButton DEFAULT={DEFAULT} />
        <FontSizeButton DEFAULT={DEFAULT} />
        <AlignButton format="align-left" IconComp={FormatAlignLeft} />
        <AlignButton format="align-center" IconComp={FormatAlignCenter} />
        <AlignButton format="align-right" IconComp={FormatAlignRight} />
        <AlignButton format="align-justify" IconComp={FormatAlignJustify} />
        <BlockButton format="numbered-list" IconComp={FormatListNumbered} />
        <BlockButton format="bulleted-list" IconComp={FormatListBulleted} />
      </Widget.FlexRow>
    </Toolbar>
  );
}

function RichTextEditor(props) {
  const isReadOnly = props.readOnly;
  const editor = useSlate();
  const isPrivacy = props.isPrivacy;
  const systemTheme = props.theme;

  let DEFAULT = {
    color: systemTheme?.theme.web.question.color,
    fontSize: `${systemTheme?.theme.web.question.size}px`,
    fontFamily: "Noto Sans TC",
    lineHeight: "1.5em",
    marginBottom: 8,
  };

  const [canShowToolbar, setCanShowToolbar] = useState(false);

  const renderElement = useCallback(props => {
    return <Element {...props} readOnly={isReadOnly} theme={systemTheme} />;
  }, []);

  const renderLeaf = useCallback(
    props => <Leaf {...props} readOnly={isReadOnly} DEFAULT={DEFAULT} />,
    []
  );
  if (props.setFontSize) {
    DEFAULT.fontSize = parseInt(props.setFontSize);
  } else {
    DEFAULT.fontSize = FontSize.title;
  }

  let wrapperStyle = {};

  const defaultStyle = {
    fontSize: DEFAULT.fontSize,
    fontFamily: DEFAULT.fontFamily,
    minHeight: "unset",
    lineHeight: "1.5em",
  };

  let userStyle = {
    ...props.styles,
  };

  if (!props.readOnly) {
    if (props.basic) {
      wrapperStyle = {
        padding: "12px 16px",
        backgroundColor: "#f4f4f4",
        borderBottom: "1px solid #8d8d8d",
      };
      if (props.admin) {
        wrapperStyle = {
          padding: "16px 12px",
          backgroundColor: "#f4f4f4",
          borderBottom: "1px solid #8d8d8d",
        };
      }
    } else if (props.endingPage) {
      wrapperStyle = {
        padding: "8px 16px 12px 16px",
        backgroundColor: "#ffffff",
        border: "1px solid rgba(0,0,0,.15)",
        borderTop: "none",
      };
    } else {
      wrapperStyle = {
        padding: "12px 16px 12px 16px",
        backgroundColor: "#f4f4f4",
        borderBottom: "1px solid #ccc",
        border: "1px solid #ccc",
        borderTop: "none",
      };
    }
  }

  return (
    <>
      {!props.readOnly && !props.basic ? (
        <>
          <AdvancedToolbar {...props} DEFAULT={DEFAULT} />
          {isPrivacy ? (
            <EditorContent>
              <Editable
                onBlur={() => {
                  editor._onBlurSelection = editor.selection;
                }}
                style={{ ...wrapperStyle, ...defaultStyle, ...userStyle }}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder={props.placeholder || "題目"}
                spellCheck
                autoFocus
                readOnly={props.readOnly}
                onKeyDown={event => {
                  for (const hotkey in HOTKEYS) {
                    // 進來了 然後呢..
                    if (isHotkey(hotkey, event)) {
                      // 這邊根本沒跑
                      event.preventDefault();
                      const mark = HOTKEYS[hotkey];
                      toggleMark(editor, mark);
                    }
                  }
                }}
                onCompositionStart={e => {
                  console.log("start = ", e);
                }}
                onCompositionEnd={e => {
                  console.log("end = ", e);
                  Transforms.setNodes(
                    editor,
                    {
                      key: +new Date(),
                    },
                    { match: Text.isText }
                  );
                }}
              />
            </EditorContent>
          ) : (
            <>
              <Editable
                onBlur={() => {
                  editor._onBlurSelection = editor.selection;
                }}
                style={{ ...wrapperStyle, ...defaultStyle, ...userStyle }}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder={props.placeholder || "題目"}
                spellCheck
                autoFocus
                readOnly={props.readOnly}
                onKeyDown={event => {
                  for (const hotkey in HOTKEYS) {
                    // 進來了 然後呢..
                    if (isHotkey(hotkey, event)) {
                      // 這邊根本沒跑
                      event.preventDefault();
                      const mark = HOTKEYS[hotkey];
                      toggleMark(editor, mark);
                    }
                  }
                }}
                onCompositionStart={e => {
                  console.log("start = ", e);
                }}
                onCompositionEnd={e => {
                  console.log("end = ", e);
                  Transforms.setNodes(
                    editor,
                    {
                      key: +new Date(),
                    },
                    { match: Text.isText }
                  );
                }}
              />
            </>
          )}
        </>
      ) : (
        <div
          onMouseEnter={e => setCanShowToolbar(true)}
          onMouseLeave={e => setCanShowToolbar(false)}
        >
          {canShowToolbar && !props.readOnly && props.basic && (
            <BasicToolbar onMoreClick={props.onMoreClick} {...props} />
          )}
          {!canShowToolbar && !props.readOnly && props.basic && (
            <div style={{ height: 32 }} />
          )}
          {isPrivacy ? (
            <EditorContent>
              <Editable
                onBlur={() => {
                  editor._onBlurSelection = editor.selection;
                }}
                style={{ ...wrapperStyle, ...defaultStyle, ...userStyle }}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder={props.placeholder || "題目"}
                spellCheck
                autoFocus
                readOnly={props.readOnly}
                onKeyDown={event => {
                  for (const hotkey in HOTKEYS) {
                    // 進來了 然後呢..
                    if (isHotkey(hotkey, event)) {
                      // 這邊根本沒跑
                      event.preventDefault();
                      const mark = HOTKEYS[hotkey];
                      toggleMark(editor, mark);
                    }
                  }
                }}
                onCompositionStart={e => {
                  console.log("start = ", e);
                }}
                onCompositionEnd={e => {
                  console.log("end = ", e);
                  Transforms.setNodes(
                    editor,
                    {
                      key: +new Date(),
                    },
                    { match: Text.isText }
                  );
                }}
              />
            </EditorContent>
          ) : (
            <>
              <Editable
                onBlur={() => {
                  editor._onBlurSelection = editor.selection;
                }}
                style={{ ...wrapperStyle, ...defaultStyle, ...userStyle }}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder={props.placeholder || "題目"}
                spellCheck
                autoFocus
                readOnly={props.readOnly}
                onKeyDown={event => {
                  for (const hotkey in HOTKEYS) {
                    // 進來了 然後呢..
                    if (isHotkey(hotkey, event)) {
                      // 這邊根本沒跑
                      event.preventDefault();
                      const mark = HOTKEYS[hotkey];
                      toggleMark(editor, mark);
                    }
                  }
                }}
                onCompositionStart={e => {
                  console.log("start = ", e);
                }}
                onCompositionEnd={e => {
                  console.log("end = ", e);
                  Transforms.setNodes(
                    editor,
                    {
                      key: +new Date(),
                    },
                    { match: Text.isText }
                  );
                }}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const judgeObj = function(obj) {
  if (Object.keys(obj).length === 0 && obj !== null) {
    return true;
  } else {
    return false;
  }
};

const toggleMark = (editor, format, value = true) => {
  // 樣式按鈕 新增刪除
  const marks = Editor.marks(editor);
  const objNull = judgeObj(marks);
  const hadLink = marks ? marks["link"] === true : false;
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    // 已存在樣式 則刪除選擇樣式
    Editor.removeMark(editor, format);
  } else if (!isActive && !hadLink && format !== "link") {
    // 目前設定內並未有超連結樣式時 並且不存在重複樣式
    Editor.addMark(editor, format, value);
  } else if (objNull && format === "link") {
    // 尚未有任何樣式 且欲新增樣式為 超連結時候 則安裝超連結樣式
    Editor.addMark(editor, format, value);
  } else if (!objNull && !hadLink && format === "link") {
    // 如果已經存在樣式 且欲新增樣式為 超連結時候 則清空樣式 並新增超連結樣式
    ALL_MARKS.forEach(mark => {
      Editor.removeMark(editor, mark);
    });
    Editor.addMark(editor, format, value);
  }
};

const isBlockActive = (editor, format) => {
  const it = Editor.nodes(editor, {
    match: n => n.type === format,
  });

  return !!it.next().value;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const setFontSizeValue = (editor, value) => {
  const marks = Editor.marks(editor);
  const hadLink = marks ? marks["link"] === true : false;
  if (!hadLink) {
    Editor.addMark(editor, "size", value.toString());
  } else {
    Editor.removeMark(editor, "size");
  }
};

const getFontSizeValue = (editor, DEFAULT) => {
  const defaultFont = DEFAULT.fontSize;
  const marks = Editor.marks(editor);
  if (marks && marks.size) {
    return marks.size;
  }
  return defaultFont;
};

const setFontColorValue = (editor, value) => {
  const marks = Editor.marks(editor);
  const hadLink = marks ? marks["link"] === true : false;

  if (!hadLink && value) {
    Editor.addMark(editor, "color", value.toString());
  } else {
    Editor.removeMark(editor, "color");
  }
};

const getFontColorValue = (editor, DEFAULT) => {
  const defaultColor = DEFAULT.color;
  const marks = Editor.marks(editor);

  if (marks && marks.color) {
    return marks.color;
  }
  return defaultColor;
};

const setHighLightColorValue = (editor, value) => {
  const marks = Editor.marks(editor);
  const hadLink = marks ? marks["link"] === true : false;
  if (!hadLink && value) {
    Editor.addMark(editor, "highlight", value.toString());
  } else {
    Editor.removeMark(editor, "highlight");
  }
};

const getHighLightColorValue = (editor, DEFAULT) => {
  const defaultColor = DEFAULT.color;
  const marks = Editor.marks(editor);
  if (marks && marks.highlight) {
    return marks.highlight;
  }
  return defaultColor;
};

const setFontValue = (editor, value) => {
  const marks = Editor.marks(editor);
  const hadLink = marks ? marks["link"] === true : false;
  if (!hadLink) {
    Editor.addMark(editor, "font", value.toString());
  } else {
    Editor.removeMark(editor, "font");
  }
};

const getFontValue = (editor, DEFAULT) => {
  const marks = Editor.marks(editor);
  if (marks && marks.font) {
    return marks.font;
  }
  return DEFAULT.fontFamily;
};

function selectText(e) {
  start = e.target.selectionStart;
  end = e.target.selectionEnd;
  return e.target.value.substring(start, end);
}

const Element = ({ attributes, children, element, readOnly, ...props }) => {
  const selected = useSelected();
  const focused = useFocused();
  switch (element.type) {
    case "question-idx":
      return (
        <span
          {...attributes}
          className="qidx"
          style={{
            width: 32,
            display: "inline-block",
            // verticalAlign: "top",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {children}
        </span>
      );
    case "block-quote":
      return <blockquote {...attributes}>{children}</blockquote>;
    case "heading-one":
      return <h1 {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;
    case "list-item":
      // let getStyles;
      // getStyles = element.children.find(function(item, index, array) {
      //   return item.type === "list-item";
      // });
      // if (getStyles === undefined) {
      //   getStyles = element.children[0].children.find(function(
      //     item,
      //     index,
      //     array
      //   ) {
      //     return item.type === "list-item";
      //   });
      // }
      const thisList = element?.children[0];

      let thisListStyles = {
        fontWeight: `${thisList?.bold ? "bold" : "unset"}`,
        fontStyle: `${thisList?.italic ? "italic" : "unset"}`,
        color: `${thisList?.color ? thisList.color : "unset"}`,
        backgroundColor: `${
          thisList?.highlight ? thisList.highlight : "unset"
        }`,
        fontFamily: `${thisList?.font ? thisList.font : "Microsoft JhengHei"}`,
        fontSize: `${
          thisList?.size ? thisList.size + `px` : FontSize.head + `px`
        }`,
        // textDecoration: `${thisList?.underline && thisList?.strikethrough ? 'underline line-through' : thisList?.underline ? 'underline' : thisList?.strikethrough ? 'line-through' : 'none'}`,
        // border: `${thisList?.border ? '1px solid' : 'unset'}`,
      };
      return (
        <ListItem
          className="listItem"
          markerStyles={thisListStyles}
          {...attributes}
          style={{ flex: "1", minWidth: "calc(100% - 32px)" }}
        >
          {children}
        </ListItem>
      );
    case "bulleted-list":
      return <Bulleted {...attributes}>{children}</Bulleted>;
    case "numbered-list":
      return <Numbered {...attributes}>{children}</Numbered>;
    case "youtube":
      return (
        <div
          {...attributes}
          style={{
            boxShadow: `${selected && focused ? "0 0 0 3px #B4D5FF" : "none"}`,
          }}
        >
          <VideoIframe
            width="400"
            height="225"
            title={`YouTube Player`}
            src={element.url}
          />
          {children}
        </div>
      );
    case "image":
      return (
        <div
          {...attributes}
          style={{
            boxShadow: `${selected && focused ? "0 0 0 3px #B4D5FF" : "none"}`,
          }}
        >
          <img
            src={element.url}
            alt="this is a text description"
            style={{
              display: "block",
              width: 200,
              height: 100,
              objectFit: "contain",
            }}
          />
          {children}
        </div>
      );
    case "align-left":
      return (
        <div
          className="alignLeft"
          {...attributes}
          style={{ textAlign: "left" }}
        >
          {children}
        </div>
      );
    case "align-right":
      return (
        <div
          className="alignRight"
          {...attributes}
          style={{ textAlign: "right" }}
        >
          {children}
        </div>
      );
    case "align-center":
      return (
        <div
          className="alignCenter"
          {...attributes}
          style={{ textAlign: "center" }}
        >
          {children}
        </div>
      );
    case "align-justify":
      return (
        <div
          className="alignJustify"
          {...attributes}
          style={{ textAlign: "justify" }}
        >
          {children}
        </div>
      );
    case "paragraph":
      return <p className="paragraph s1">{children}</p>;
    default:
      return (
        <p className="paragraph s2" {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf, DEFAULT }) => {
  let extraStyle = {
    ...DEFAULT,
  };

  if (leaf.header_one) {
    children = <h1>{children}</h1>;
  }
  if (leaf.header_two) {
    children = <h2>{children}</h2>;
  }
  if (leaf.privacycontent) {
    children = <h3>{children}</h3>;
  }
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }
  if (leaf.code) {
    children = <code>{children}</code>;
  }
  if (leaf.italic) {
    children = <em>{children}</em>;
  }
  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  if (leaf.strikethrough) {
    extraStyle.textDecoration = "line-through";
  }
  if (leaf.highlight) {
    extraStyle.backgroundColor = leaf.highlight; //"yellow";
  }
  if (leaf.border) {
    extraStyle.border = `1px solid`;
  }
  if (leaf.color) {
    extraStyle.color = leaf.color;
  }
  if (leaf.size) {
    extraStyle.fontSize = parseInt(leaf.size);
  }
  if (leaf.align) {
    extraStyle.textAlign = leaf.align;
  }
  if (leaf.font) {
    extraStyle.fontFamily = leaf.font;
  }

  if (leaf.link) {
    children = (
      <span
        className="important-styles cursor-pointer mb-0"
        onClick={() => window.open(leaf.text)}
      >
        {children}
      </span>
    );
  }

  return (
    <span {...attributes} className="paragraph s3" style={extraStyle}>
      {children}
    </span>
  );
};

const AlignButton = ({ format, icon, IconComp }) => {
  const editor = useSlate();
  const active = isBlockActive(editor, format);
  return (
    <Button
      type="text"
      onMouseDown={event => {
        event.preventDefault();
        toggleAlign(editor, format);
      }}
    >
      {!!IconComp ? (
        <IconComp size={FontSize.subTitle} color={active ? "white" : "#aaa"} />
      ) : (
        <Icon>{icon}</Icon>
      )}
    </Button>
  );
};

const toggleAlign = (editor, format) => {
  const isActive = isBlockActive(editor, format);

  clearAlignmentBlocks(editor);

  if (!isActive) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const BlockButton = ({ format, icon, IconComp }) => {
  const editor = useSlate();
  const active = isBlockActive(editor, format);
  return (
    <Button
      type="text"
      onMouseDown={event => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {!!IconComp ? (
        <IconComp size={FontSize.subTitle} color={active ? "white" : "#aaa"} />
      ) : (
        <Icon>{icon}</Icon>
      )}
    </Button>
  );
};

const MarkButton = ({ format, icon, IconComp, value = true }) => {
  const editor = useSlate();
  const active = isMarkActive(editor, format);

  return (
    <Button
      type="text"
      onMouseDown={event => {
        event.preventDefault();
        toggleMark(editor, format, value);
      }}
    >
      {!!IconComp ? (
        <IconComp size={FontSize.subTitle} color={active ? "white" : "#ccc"} />
      ) : (
        <Icon>{icon}</Icon>
      )}
    </Button>
  );
};

// alignItems: "center"
// backgroundColor: "#484e9d"
// color: "#22c93e"
// display: "flex"
// flexWrap: "wrap"
// fontFamily: "Microsoft JhengHei"
// fontSize: "48px"
// fontStyle: "none"
// fontWeight: "none"
// listStyle: "inside"
// width: "100%"
const ListItem = styled.li`
  &::marker {
    background-color: ${props =>
      props.markerStyles?.backgroundColor &&
      props.markerStyles.backgroundColor};
    color: ${props => props.markerStyles?.color && props.markerStyles.color};
    font-family: ${props =>
      props.markerStyles?.fontFamily && props.markerStyles.fontFamily};
    font-style: ${props =>
      props.markerStyles?.fontStyle && props.markerStyles.fontStyle};
    font-weight: ${props =>
      props.markerStyles?.fontWeight && props.markerStyles.fontWeight};
    font-size: ${props =>
      props.markerStyles?.fontSize && props.markerStyles.fontSize};
  }
`;

const Bulleted = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  list-style: inside;
  & .alignLeft,
  & .alignRight,
  & .alignCenter,
  & .alignJustify {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
`;

const Numbered = styled.ol`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  list-style-position: inside;
  & .alignLeft,
  & .alignRight,
  & .alignCenter,
  & .alignJustify {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
`;

// Ant目前有Bug，popover overlayStyle 無效，只好包一層
const PopoverRemovePadding = styled.div`
  .ant-popover-inner-content {
    padding: 0px !important;
  }
`;

const EditorContent = styled.div`
  h3 {
    margin: 0 0 0 32px;
    padding-bottom: 9px;
    span {
      font-family: PingFangTC;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: #66727c;
    }
  }
  h1 {
    span {
      font-family: PingFangTC;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #192b3a;
    }
    padding-bottom: 8px;
  }
  h2 {
    margin: 0 0 0 12px;
    padding-bottom: 5px;
    span {
      font-family: PingFangTC;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: #324250;
    }
    /* ul {
      margin: 0;
    }
    li {
      font-family: PingFangTC;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: #66727c;
    } */
  }
  span {
    font-family: PingFangTC;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #66727c;
  }
`;

export default RichTextEditor;
