/** 主要API設定 */

//const DEV_URL = "http://139.162.67.143:8081";
const DEV_URL = "https://survey-mgt.chatok.io";
// const DEV_URL = "https://testecrmsurvey.cht.com.tw";

// const DEV_URL = "https://testecrmsurvey.cht.com.tw";
// const DEV_URL = "https://ecrmsurvey.cht.com.tw";
//
// const DEV_URL = "https://testtelesurvey.cht.com.tw";
// const DEV_URL = "https://telesurvey.cht.com.tw";

/** 如果是正式站直接留空，call自己 */
const API_URL = process.env.NODE_ENV === "development" ? DEV_URL : "";

/** 分離設定 */
const hostname = typeof window !== "undefined" && window.location.hostname;
// 填寫站台DOMAIN：後台收集方式會用到
var SURVEY_DOMAIN = hostname;
if (hostname === "ecrmsurvey.cht.com.tw") {
  SURVEY_DOMAIN = "https://telesurvey.cht.com.tw";
}
if (hostname === "testecrmsurvey.cht.com.tw") {
  SURVEY_DOMAIN = "https://testtelesurvey.cht.com.tw";
}
if (hostname === "survey-mgt.chatok.io") {
  SURVEY_DOMAIN = "https://survey.chatok.io";
}

const Settings = {
  env: "stg", //環境變數
  apiUrl: API_URL, //後端api URL
  apiTokenUrl: API_URL, //"http://139.162.67.143:8081",後端api Token URL
  /** 填寫站台DOMAIN */
  SURVEY_DOMAIN,
  debug: true, //是否要啟用debug
  /** useMock：true 使用問卷假資料 */
  useMock: false,
  /** useProxy: true:啟用代理伺服器，啟用後proxyHost生效 */
  useProxy: false, //是否用代理伺服器，啟用後proxyHost生效
  /** proxyHost: 本地開發代理伺服器 */
  proxyHost:
    "https://xzolnm24n6.execute-api.ap-northeast-1.amazonaws.com/dev/proxy",
  /** externalIdleTimerDuration: 外部填寫閒置登出時間 */
  externalIdleTimerDuration: 480 * 60,
  /** internalIdleTimerDuration: 內部問卷閒置登出時間 */
  internalIdleTimerDuration: 30 * 60,
  USER_TYPE: {
    MGT: 1,
    CDP: 2,
  },
  // CRM的問卷沒有此欄位，CDP問卷標註為字串"cdp"
  SURVEY_TYPE: {
    CDP: "cdp",
  },
  /**
   * Review 2024-11-06
   * SURVEY_STATUS: 填寫頁面用來卡控呈現畫面與階段性的參數，拉為常數利於辨認
   * 一開始由API回傳初始狀態，後來由React前端控制
   * 參考SurveyWebAPI中的SurveyStatusEnum，真對大小寫進型條整
   *    NotAvailable          = 1, // 未開放，表示問卷尚未開放
   *    Available             = 2, // 開放填寫中，表示問卷開放，且可填寫
   *    Expired               = 3, // 已截止，表示問卷已截止，無法再提交
   *    Full                  = 4, // 已額滿，表示問卷的可填寫人數已滿
   *    ValidationFailed      = 5, // 參數傳遞驗證失敗，表示問卷參數驗證失敗
   *    FullForTest           = 6, // 測試問卷已額滿，表示測試問卷已達人數上限
   *    SurveyNotFound        = 7, // CRM不存在問卷，表示在CRM中未找到問卷。現在代表CRM與CDP兩個來源都找不到問卷
   *    ShowWelcomePrivacy    = 11, // 問卷隱私權條款未勾選，表示問卷須要顯示歡迎頁面，讓用戶勾選隱私權條款
   *    SystemException       = -1 // 系統異常，表示系統發生錯誤
   *
   * 以下為React前端使用狀態進行說明
   * 1 問卷不存在
   * 2 正常問卷
   * 2 填寫登入資料頁面(符合資格才能填寫) validRegister = 2 || undefined 填寫個資
   * 3 本問卷已截止
   * 4 測試問卷已額滿
   * 5 參數傳遞驗證失敗
   * 11 個資同意
   * others 請確定網路連結正確 (位於上述狀態列到的項目：6,7,-1，皆呈現此頁面)
   */
  SURVEY_STATUS: {
    NOT_AVAILABLE: 1,
    AVAILABLE: 2,
    EXPIRED: 3,
    FULL: 4,
    VALIDATION_FAILED: 5,
    FULL_FOR_TEST: 6,
    SURVEY_NOT_FOUND: 7,
    SHOW_WELCOME_PRIVACY: 11,
    SYSTEM_EXCEPTION: -1,
  },
  /**
   * validRegister
   * 1=匿名填寫
   * 2=資格驗證，需輸入通行碼（CDP只有行動電話or其他文字格式）
   * 3=參數傳遞 (CDP不用特別傳參數，只要透過自訂渠道發放，都會對應到CDP的聯絡人ID)
   */
  SURVEY_VALID_REGISTER: {
    ANONYMOUS: 1,
    VALIDATE: 2,
    PARAMS: 3,
  },
  /**
   * CDP正式區測試問題: PROD#58#59
   * 允許來源傳送token資訊
   */
  ALLOWED_ORIGINS: [
    "http://cdp.crm7.dynamics.com",
    "http://orgf8a32e97.crm5.dynamics.com",
    "http://syscomtw.crm7.dynamics.com",
  ],
};

module.exports = Settings;
