export function email(v) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(v).toLowerCase());
}

export function identityNumber(v) {
  const re = /^[A-Z][0-9]{9}$/;
  return re.test(String(v).toUpperCase());
}

export function phone(v) {
  const re = /^09[0-9]{8}$/;
  return re.test(String(v));
}

export function hn(v) {
  const re = /^[0-9]+$/;
  return re.test(String(v));
}

export function md(v) {
  const re = /^[0-9]+$/;
  return re.test(String(v));
}
