import React, { useState } from "react";
import Widget, { Color, Font, FontSize } from "../Widget";
import styled from "styled-components";
import { SketchPicker } from "react-color";

// 取色器預設寬度
const PickerWidth = 234;

const ColorPicker = props => {
  const [color, setColor] = useState();
  return (
    <Wrapper>
      <Widget.FlexRow>
        <SketchPicker
          disableAlpha={true}
          presetColors={[]}
          color={color}
          onChange={color => {
            setColor(color.hex);
          }}
          width={PickerWidth}
        />
      </Widget.FlexRow>
      <div className="sketch-block">
        <Widget.Button
          type="link"
          style={{ color: Color.Purple }}
          onClick={() => props.setVisible(false)}
        >
          取消
        </Widget.Button>
        <Widget.Button
          style={{ borderColor: Color.Purple, color: Color.Purple }}
          onClick={() => props.addColor(color)}
        >
          確定
        </Widget.Button>
      </div>
    </Wrapper>
  );
};

export { ColorPicker };

const Wrapper = styled.div`
  width: ${PickerWidth}px;
  background-color: ${Color.White};
  & .sketch-picker {
    box-shadow: none !important;
  }

  & .sketch-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
  }
`;
