import React, { useState, useEffect } from "react";
import * as Ant from "antd";
import Widget, { Font, Color } from "./Widget";
import { Image } from "styled-icons/material";
import { ExclamationCircleFilled, CheckCircleFilled } from "@ant-design/icons";

let timer;
/**
 * 影片新增 Modal
 * @param {*} props
 * @returns
 */
export default function AddVideoModal(props) {
  const {
    visible,
    isContentVideo: isContent,
    contentUrl,
    currUrl,
    onCancel,
    insertVideo,
  } = props;
  // const { isContentVideo, contentUrl: } = props;
  const [isChecked, setIsChecked] = useState(true);
  const [errorType, setErrorType] = useState(-1);
  const [isFormatValid, setIsFormatValid] = useState(true);
  const [isVideoValid, setIsVideoValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [videoId, setVideoId] = useState("");
  const [videoText, setVideoText] = useState("");

  useEffect(() => {
    if (!visible) {
      setVideoId("");
      setVideoText("");
    }
  }, [visible]);

  useEffect(() => {
    const url = (isContent ? contentUrl : currUrl) || "";
    if (url) {
      setVideoText(url);
    }
  }, [isContent, contentUrl]);

  useEffect(() => {
    if (videoId) {
      checkVideoValid(videoId);
    }
  }, [videoId]);

  /**
   * url to youtube id
   * @param {*} url
   * @returns
   */
  function urlToVideoId(url = "") {
    const regex = /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v\=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/;
    const matched = url.match(regex);
    if (matched) {
      return matched?.[1];
    }
    return "";
  }

  /**
   * 檢查youtube id 是否合法
   * @param {} id
   * @returns
   */
  async function checkVideoValid(id) {
    setIsLoading(true);
    if (!id) {
      return;
    }
    try {
      const res = await fetch(
        `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${id}&format=json`,
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      const { ok, status } = res;
      console.log(status);
      if (ok) {
        const data = await res.json();
        if (data?.title) {
          setIsVideoValid(true);
          setErrorType(null);
        }
      } else if (status === 401) {
        setErrorType(2);
      } else if (status === 400) {
        setErrorType(1);
      }
    } catch (e) {
      setErrorType(1);
    }
    setIsLoading(false);
  }

  /**
   * 當inpu
   * @param {}} e
   */
  function onVideoTextChange(e) {
    const text = e.target.value;
    setVideoText(text);

    setIsChecked(false);
    clearTimeout(timer);
    timer = setTimeout(() => {
      setErrorType(-1);
      if (text) {
        const id = urlToVideoId(text);
        if (!id) {
          setErrorType(0);
        }
        setVideoId(id);
      } else {
        setVideoId("");
      }
      setIsChecked(true);
    }, 600);
  }

  /**
   * 當按下ok時
   */
  function onOk() {
    // 後端一律存完整網址
    insertVideo(videoText);
    onCancel();
  }

  const showError =
    videoText.length > 0 &&
    (errorType === 0 || errorType === 1 || errorType === 2);

  const showSuccess = videoText.length > 0 && errorType === null;

  function getIcon() {
    if (showSuccess) {
      return <CheckCircleFilled style={{ color: "#6dd400" }} size={14} />;
    } else if (showError) {
      return <ExclamationCircleFilled style={{ color: Color.Red }} size={14} />;
    }
    return null;
  }

  return (
    <Ant.Modal
      className="custom-modal"
      title="新增Youtube影片"
      visible={visible}
      bodyStyle={{ padding: 0, backgroundColor: "#efefef" }}
      onCancel={onCancel}
      okButtonProps={{
        // loading: isChecked,
        disabled: isLoading || !isChecked || videoText.length <= 0 || showError,
      }}
      onOk={onOk}
      okText="確定"
      cancelText="取消"
    >
      <div
        style={{
          paddingTop: 24,
          paddingRight: 37,
          paddingBottom: 41,
          paddingLeft: 31,
        }}
      >
        <Font.Body style={{ color: Color.GreyBlack, marginBottom: 18 }}>
          影片來源限定使用 YouTube
        </Font.Body>
        <Ant.Row align="" gutter={[12, 12]}>
          <Ant.Col flex="110px">
            <img
              src="/images/youtube-logo.svg"
              style={{ width: "100%", display: "block", marginTop: 4 }}
            />
          </Ant.Col>
          <Ant.Col flex="auto">
            <Ant.Input
              placeholder={isContent ? contentUrl : currUrl}
              value={videoText}
              onChange={onVideoTextChange}
              // onBlur={checkVideoValid}
              style={{
                width: "100%",
                margin: "0 0 4px 0",
                borderColor:
                  showError || showSuccess
                    ? showSuccess
                      ? "#6dd400"
                      : Color.Red
                    : "",
              }}
              suffix={getIcon()}
            />
            {showSuccess && (
              <Font.Small style={{ color: "#6dd400" }}>
                已成功偵測到Youtube影片
              </Font.Small>
            )}
            {showError && (
              <>
                {errorType === 0 && (
                  <Font.Small style={{ color: "#fa5050" }}>
                    目前不支援非 YouTube 影片
                  </Font.Small>
                )}
                {errorType === 1 && (
                  <Font.Small style={{ color: "#fa5050" }}>
                    找不到該影片，請重新確認 YouTube 來源網址
                  </Font.Small>
                )}
                {errorType === 2 && (
                  <Font.Small style={{ color: "#fa5050" }}>
                    影片擁有者已禁止在其他網站上播放這部影片
                  </Font.Small>
                )}
              </>
            )}
          </Ant.Col>
        </Ant.Row>
      </div>
    </Ant.Modal>
  );
}
