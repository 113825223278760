import React, { useMemo, useState, useEffect } from "react";

function urlToVideoId(url = "") {
  const regex = /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v\=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/;
  const matched = url.match(regex);
  if (matched) {
    return matched?.[1];
  }
  return "";
}

function VideoIframe(props) {
  const { src = "", ...rest } = props;
  const videoId = urlToVideoId(src);
  return (
    <iframe
      title={`YouTube Player for content video`}
      src={`https://www.youtube.com/embed/${videoId}`}
      frameBorder="no"
      border="0"
      // frameborder="0"
      allowFullScreen={true}
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      {...rest}
    />
  );
}

export default VideoIframe;
