import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import * as Ant from "antd";
import styled from "styled-components";
import Widget from "./Widget";
import getCroppedImg from "./CropImage.crop";

/* reference: https://github.com/ricardo-ch/react-easy-crop */
export default function CropImage(props) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    // console.log(croppedArea, croppedAreaPixels);
    props.setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  return (
    <Widget.FlexCenter>
      <Widget.FlexCenter
        style={{
          width: 334,
          height: 250,
          backgroundColor: "grey",
          position: "relative",
        }}
      >
        <Cropper
          image={props.image}
          crop={crop}
          zoom={zoom}
          aspect={props.imageType !== "image/gif" ? 4 / 3 : 17 / 10}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          showGrid={false}
        />
      </Widget.FlexCenter>
      {props.imageType !== "image/gif" && (
        <Control className={props?.className || ""}>
          <Ant.Slider
            min={1}
            max={2}
            step={0.05}
            value={zoom}
            onChange={setZoom}
          />
        </Control>
      )}
    </Widget.FlexCenter>
  );
}

const Control = styled.div`
  width: 100%;
  &.bar-none {
    display: none;
  }
  .ant-slider {
    .ant-slider-rail {
      background-color: #ffffff;
    }
    .ant-slider-track {
      background-color: rgba(109, 190, 255, 0.4);
    }
    &:hover,
    &:active {
      .ant-slider-track {
        background-color: rgba(109, 190, 255, 0.8);
      }
    }
    .ant-slider-rail,
    .ant-slider-track {
      height: 10px;
      border-radius: 999rem;
      transition: background-color 0.15s;
      z-index: 1;
    }
    .ant-slider-step {
      height: 10px;
      border-radius: 999rem;
    }
    .ant-slider-handle {
      width: 16px;
      height: 16px;
      z-index: 2;
      margin-top: -3px;
      background-color: #209cff;
      border-color: #209cff;
      -webkit-box-shadow: -3px 0 5px rgba(0, 0, 0, 0.45);
      -moz-box-shadow: -3px 0 5px rgba(0, 0, 0, 0.45);
      box-shadow: -3px 0 5px rgba(0, 0, 0, 0.45);
      &:focus {
        background-color: #53b3ff;
        border-color: #53b3ff;
      }
      &.ant-tooltip-open {
        background-color: #0075d3;
        border-color: #0075d3;
      }
    }
    .ant-slider-mark {
    }
  }
`;
