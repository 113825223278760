const constraintCode = {
  any: 1,
  all: 2,
};
const logicTypeCode = {
  jump: 1,
  prevent: 2,
  forceQuit: 3,
  preventByQuestion: 4,
};
const conditionCode = {
  eq: 1,
  ueq: 2,
  ans: 3,
  uans: 4,
};

function LogicRuleMapToApi(survey, unsavedLogic) {
  //console.log('LogicRuleMapToApi survey.questions:', survey.questions);
  //console.log('LogicRuleMapToApi unsavedLogic:', unsavedLogic);
  let questionOptions = {};
  survey.questions.map(questionItem => {
    //console.log('questionItem:', questionItem);
    //console.log('questionItem ID:', questionItem.id);
    if (questionItem.options) {
      questionOptions["k" + questionItem.id] = questionItem.options;
    }
  });
  //console.log('questionOptions:', JSON.stringify(questionOptions, null, 2));
  let data = {
    SurveyId: survey.id,
    LogicList: [
      {
        LogicType: logicTypeCode[unsavedLogic.type],
        LogicCondition: constraintCode[unsavedLogic.constraint],
        TargetQuestionId: "",
        BlockOptionList: "",
        ConditionList: [],
      },
    ],
  };
  let BlockQuestion = "";
  unsavedLogic.rules.map(item => {
    let options = [];
    BlockQuestion = item.question;
    item.options.map(optionItem => {
      //let tempArray = questionOptions[item.question]
      if (questionOptions["k" + item.question]) {
        if (questionOptions["k" + item.question][optionItem]) {
          options.push(questionOptions["k" + item.question][optionItem].id);
        }
      }
    });
    //console.log('unsavedLogic options:',options)
    let condition = {
      ConditionQuestionList: item.question,
      ConditionOptionList: options.join(";"),
      ConditionRule: conditionCode[item.condition],
    };
    if (item.MatrixField) {
      condition.MatrixField = item.MatrixField;
    }
    data.LogicList[0].ConditionList.push(condition);
  });
  if (unsavedLogic.type === "jump") {
    data.LogicList[0].TargetQuestionId = unsavedLogic.action;
  } else if (unsavedLogic.type === "prevent") {
    let BlockOptions = [];
    unsavedLogic.action.map(actionItem => {
      if (questionOptions["k" + BlockQuestion]) {
        BlockOptions.push(questionOptions["k" + BlockQuestion][actionItem].id);
      }
    });
    data.LogicList[0].BlockOptionList = BlockOptions.join(";");
  } else if (unsavedLogic.type === "preventByQuestion") {
    data.LogicList[0].TargetQuestionId = unsavedLogic.action;
    if (unsavedLogic.blockOptionList.length > 0)
      data.LogicList[0].BlockOptionList = unsavedLogic.blockOptionList.join(
        ";"
      );
    else data.LogicList[0].BlockOptionList = null;
    // console.log('data', data);
    // console.log('action', unsavedLogic.action);
    // console.log('blockOptionList', unsavedLogic.blockOptionList);
  }
  return data;
}

function LogicRuleMapToModel(survey, logicResp) {
  const constraintType = ["", "any", "all"];
  const logicType = ["", "jump", "prevent", "forceQuit", "preventByQuestion"];
  const conditionType = [undefined, "eq", "ueq", "ans", "uans"];
  let data = [];
  let questions = {};
  survey.questions.map(questionItem => {
    questions["k" + questionItem.id] = {};
    if (questionItem.options) {
      questionItem.options.map((optionItem, optionIndex) => {
        questions["k" + questionItem.id]["k" + optionItem.id] = optionIndex;
      });
    }
  });
  logicResp.map(logicItem => {
    let logic = {
      id: logicItem.LogicId,
      type: logicType[logicItem.LogicType],
      constraint: constraintType[logicItem.LogicCondition],
      rules: [],
      // 原始邏輯中，跳題邏輯設定的題目，選項防呆設定的選項，都以ID方式存放在 action
      action: [],
      needAdjust: false,
      // 因為 跨題選項(type == 4) 設定 題目 + 選項，所以新增屬性，存放選項資訊
      blockOptionList: [],
    };
    let preventQuestion = "";
    if (logicItem.ConditionList) {
      logicItem.ConditionList.map(conditionItem => {
        let rule = {};
        preventQuestion = conditionItem.ConditionQuestionList;
        rule.question = conditionItem.ConditionQuestionList;
        rule.condition = conditionType[conditionItem.ConditionRule];
        rule.options = [];
        if (conditionItem.MatrixField) {
          rule.MatrixField = conditionItem.MatrixField;
        }
        let optionList = [];
        if (conditionItem.ConditionOptionList != null) {
          optionList = conditionItem.ConditionOptionList.split(";");
        }
        optionList.map(optionItem => {
          if (questions["k" + rule.question]) {
            if (
              questions["k" + rule.question]["k" + optionItem] !== null &&
              questions["k" + rule.question]["k" + optionItem] !== undefined
            ) {
              rule.options.push(
                questions["k" + rule.question]["k" + optionItem]
              );
            }
          }
        });
        logic.rules.push(rule);
      });
    }
    if (logicItem.LogicType === 1) {
      logic.action = logicItem.TargetQuestionId;
    } else if (logicItem.LogicType === 2) {
      let BlockOptionList = [];
      if (logicItem.BlockOptionList != null) {
        BlockOptionList = logicItem.BlockOptionList.split(";");
      }
      BlockOptionList.map(blockOptionItem => {
        if (questions["k" + preventQuestion]) {
          if (
            questions["k" + preventQuestion]["k" + blockOptionItem] !== null &&
            questions["k" + preventQuestion]["k" + blockOptionItem] !==
              undefined
          ) {
            logic.action.push(
              questions["k" + preventQuestion]["k" + blockOptionItem]
            );
          }
        }
      });
    } else if (logicItem.LogicType === 4) {
      // 跨題選項 type == 4
      // 因為 跨題選項 設定 題目 + 選項，所以新增選項資訊
      // action : 題目id， string
      // blockOptionList : 選項id， array
      logic.action = logicItem.TargetQuestionId;
      if (logicItem.BlockOptionList) {
        logic.blockOptionList = logicItem.BlockOptionList.split(";");
      }
    } else {
      logic.action = null;
    }
    logic.needAdjust = logicCheck(survey.questions, logicItem);
    data.push(logic);
  });
  //console.log('logicTest:',data);
  return data;
}

// 檢核是否設定之題目、選項是否存在，或是跳題邏輯有沒有問題
// 後續於邏輯設計時，會以紅框呈現
function logicCheck(questions, logic) {
  // console.log('questions, logic', questions, logic);
  const { LogicType, BlockOptionList, ConditionList, TargetQuestionId } = logic;
  // console.log('LogicType', LogicType, 'BlockOptionList',BlockOptionList, 'ConditionList',ConditionList, 'TargetQuestionId', TargetQuestionId);
  let needAdjust = false;

  // 2022-08-19 條件需必填，檢核
  if (!Array.isArray(ConditionList) || ConditionList.length === 0) {
    return true;
  }
  // 針對買一個條件，檢核是否有空值
  for (const Condition of ConditionList) {
    if (
      !Condition.ConditionQuestionList ||
      Condition.ConditionQuestionList === "" ||
      !Condition.ConditionQuestionList ||
      Condition.ConditionQuestionList === ""
    ) {
      return true;
    }
  }

  // 真對每一種邏輯Type檢核目標是否有選擇
  // 跳題邏輯，需要設定目標問題
  if (LogicType === 1 && (!TargetQuestionId || TargetQuestionId === "")) {
    return true;
  }
  // 選項防呆，需要設定目標選項
  if (LogicType === 2 && (!BlockOptionList || BlockOptionList === "")) {
    return true;
  }
  // 跨題項防呆，需要設定目標問題、選項
  if (
    LogicType === 4 &&
    (!TargetQuestionId || TargetQuestionId === "") &&
    (!BlockOptionList || BlockOptionList === "")
  ) {
    return true;
  }

  // 整理邏輯中Question, Option
  let questionList = [];
  let optionList = [];
  if (questions) {
    questions.forEach(question => {
      // 確認為認知錯誤，全部題型都需要檢核，所以註解if條件
      // 目前問卷邏輯，能設定的題型為單選、多選、矩陣
      // if (
      //   question.type === "SINGLE_CHOICE" ||
      //   question.type === "MULTIPLE_CHOICE" ||
      //   question.type === "MATRIX"
      // ) {
      questionList.push(question.id);

      const { options } = question;
      if (options) {
        options.forEach(option => {
          optionList.push(option.id);
        });
      }
      // }
    });
  }
  // console.log('questionList, optionList', questionList, optionList);

  // 整理邏輯中Question, Option
  let logicQuestionList = [];
  let logicOptionList = [];

  if (BlockOptionList) {
    BlockOptionList.split(";").forEach(opt => logicOptionList.push(opt));
  }

  ConditionList.forEach(condition => {
    const { ConditionOptionList, ConditionQuestionList } = condition;
    if (ConditionOptionList) {
      ConditionOptionList.split(";").forEach(opt => logicOptionList.push(opt));
    }
    if (ConditionQuestionList) {
      ConditionQuestionList.split(";").forEach(opt =>
        logicQuestionList.push(opt)
      );
    }
  });

  if (TargetQuestionId) {
    logicQuestionList.push(TargetQuestionId);
  }
  // console.log('logicQuestionList, logicOptionList', logicQuestionList, logicOptionList);

  // 進行檢核，取差集
  let questionsResult = logicQuestionList.filter(e => {
    return questionList.indexOf(e) === -1;
  });
  let optionsResult = logicOptionList.filter(e => {
    return optionList.indexOf(e) === -1;
  });
  // console.log('questionsResult,optionsResult', questionsResult, optionsResult);
  // console.log('needAdjust', needAdjust);
  // 差集完，若還有剩餘資料，表示已有Question or Option被刪除，但是Logic未更新
  if (questionsResult.length > 0 || optionsResult.length > 0) {
    needAdjust = true;
  }
  // console.log('questionsResult, optionsResult', questionsResult, optionsResult);
  // console.log('needAdjust', needAdjust);

  // 跳題邏輯，需要額外檢核條件題目順序是否比目標題目後面
  if (logic.LogicType === 1) {
    // 目標題目
    const targetQuestion = questions.find(
      question => question.id === TargetQuestionId
    );

    // 條件題目處理
    ConditionList.forEach(condition => {
      const { ConditionQuestionList } = condition;
      if (ConditionQuestionList) {
        ConditionQuestionList.split(";").forEach(opt => {
          const conditionQuestion = questions.find(
            question => question.id === opt
          );
          // console.log('conditionQuestion', conditionQuestion, 'targetQuestion',targetQuestion);
          if (targetQuestion && conditionQuestion)
            if (targetQuestion._idx < conditionQuestion._idx) needAdjust = true;
        });
      }
    });
  }
  return needAdjust;
}

module.exports = {
  LogicRuleMapToApi,
  LogicRuleMapToModel,
};
