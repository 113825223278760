const surveyData = {
  survey: {
    id: "1",
    title: "Hinet 非固定制退租客戶關懷調查 2020Q1",
    status: "1",
    audit: false,
    responseCount: null,
    author: "David",
    startDate: "2021-10-07T08:04:21.4497444",
    endDate: "2021-12-07T08:04:21.4497444",
    modified: "2020-09-07T08:04:21.4497444",
    pageIdxDisplay: false,
    questionIdxDisplay: false,
    asteriskDisplay: false,
    progressDisplay: false,
    progressDisplayDetail: { position: "bottom", display: "percentage" },
    validationCode: false,
    oneQuestionPerPage: false,
    endingDisplay: true,
    welcomeDisplay: true,
    themeConfig: {
      backgroundColor: "#fbfbfb",
      headerImg: "",
      mobileHeaderImg: "",
      styleType: "customized",
    },
    questions: [
      { id: "0", type: "PAGE" },
      {
        id: "1",
        type: "SINGLE_CHOICE",
        title: "Test title",
        content: [
          { type: "paragraph", children: [{ text: "單選題題目編輯！" }] },
        ],
        contentVideo: "",
        contentImage:
          "https://pbs.twimg.com/profile_images/1293217550050799617/8LFWTYD3.jpg",
        description: "Test description",
        options: [
          { value: "是(包含承租的住家)" },
          {
            value:
              "否，非住家(如：公司、店鋪、工作室、餐廳、工廠、學校、魚塭、出租用等)",
          },
          { value: "Others..." },
        ],
        required: true,
        advanced: { display: null, random: null },
      },
      {
        id: "2",
        type: "MULTIPLE_CHOICE",
        content: [{ type: "paragraph", children: [{ text: "多選題題目" }] }],
        title: "Test title",
        description: "Test description",
        options: [
          { value: "選項一" },
          { value: "選項二" },
          { value: "選項三" },
        ],
        advanced: { limit: null, display: null, random: null },
      },
      {
        id: "3",
        type: "FILL_IN",
        content: [
          { type: "paragraph", children: [{ text: "請問你家裡居住幾個人？" }] },
        ],
        title: "Test title",
        description: "Test description",
        answerPlaceholder: null,
        validateAnswer: { type: "none" },
      },
      { id: "4", type: "PAGE" },
      {
        id: "5",
        type: "MATRIX",
        rows: ["欄位", "欄位"],
        cols: ["選項", "選項"],
        content: [
          {
            type: "paragraph",
            children: [{ text: "請問您平日從事以下影視娛樂使用行為的頻率？" }],
          },
        ],
        title: "Test title",
        description: "Test description",
      },
      {
        id: "6",
        type: "BASIC_INFO",
        content: [
          { type: "paragraph", children: [{ text: "請輸入您的身分證字號" }] },
        ],
        infoType: "id",
      },
      {
        id: "7",
        type: "TEXT",
        content: [{ type: "paragraph", children: [{ text: "感謝您！" }] }],
      },
    ],
  },
  logicList: [
    {
      id: "1599898591870",
      type: "jump",
      constraint: "any",
      rules: [{ question: "1", condition: "eq", options: [1] }],
      action: "3",
    },
    {
      id: "1599898633181",
      type: "prevent",
      constraint: "any",
      rules: [{ question: "2", condition: "eq", options: [0] }],
      action: 2,
    },
    {
      id: "1599898676910",
      type: "forceQuit",
      constraint: "any",
      rules: [{ question: "1", condition: "eq", options: [2] }],
      action: null,
    },
  ],
};

module.exports = surveyData;
