/* Model: define field, data structure for main data in syscom */
// libs
const deepCopy = require("deepcopy");
const _ = require("lodash");
import * as FormatValidator from "./Utils/FormatValidation";
import { numericalDefaultOption } from "./Components/Question.numerical";

const REAL_QUESTIONS = [
  "SINGLE_CHOICE",
  "MULTIPLE_CHOICE",
  "FILL_IN",
  "MATRIX",
  "BASIC_INFO",
  "NUMERICAL",
];
// function _extractNestedQuestions({ q, questions }) {
//   const linkedQuestions = [];
//   if (["SINGLE_CHOICE", "MULTIPLE_CHOICE"].indexOf(q.type) > -1) {
//     q.options.forEach(option => {
//       if (option.connectTo) {
//         const targetIdx = questions.findIndex(_q => _q.id === option.connectTo);
//         console.log('targetIdx >>>', targetIdx)
//         const linkedQuestionsIdx = linkedQuestions.findIndex(
//           lq => lq.id === option.connectTo
//         );
//         if (targetIdx === -1 && linkedQuestionsIdx === -1) {
//           console.log('reset!!!!!')
//           option.connectTo = undefined; // unset non-reachable target
//         } else {
//           const [targetQuestion] = questions.splice(targetIdx, 1);
//           linkedQuestions.push(targetQuestion);
//         }
//       }
//     });
//   }
//   return linkedQuestions;
// }

function _extractConnectedQuestions({ q, connectedQuestions }) {
  const linkedQuestions = [];
  if (["SINGLE_CHOICE", "MULTIPLE_CHOICE"].indexOf(q.type) > -1) {
    q.options.forEach(option => {
      if (option.connectTo) {
        const targetIdx = connectedQuestions.findIndex(
          _q => _q.id === option.connectTo
        );
        const linkedQuestionsIdx = linkedQuestions.findIndex(
          lq => lq.id === option.connectTo
        );
        if (targetIdx === -1 && linkedQuestionsIdx === -1) {
          option.connectTo = undefined; // unset non-reachable target
        } else if (linkedQuestionsIdx !== -1) {
          // note: already in linkedQuestions! by pass.
        } else {
          const [targetQuestion] = connectedQuestions.splice(targetIdx, 1);
          linkedQuestions.push(targetQuestion);
        }
      }
    });
  }
  return linkedQuestions;
}

export function refineQuestions(questions) {
  let result = [];
  questions = deepCopy(questions);

  // note: duplicate of Survey.listConnectedIds
  const _listConnectedIds = questions => {
    const list = [];
    for (const q of questions) {
      if (q.type === "SINGLE_CHOICE" || q.type === "MULTIPLE_CHOICE") {
        for (const opt of q.options) {
          if (opt.connectTo !== undefined) {
            list.push(opt.connectTo);
          }
        }
      }
    }

    return list;
  };

  const connectedIds = _listConnectedIds(questions);
  // note: [子題,母題] 時，子題不可先於母題被 splice 出去
  let _questions = questions.filter(q => connectedIds.indexOf(q.id) === -1);
  let _connectedQuestions = questions.filter(
    q => connectedIds.indexOf(q.id) !== -1
  );

  while (_questions.length > 0) {
    const [q] = _questions.splice(0, 1);
    const linkedQuestions = _extractConnectedQuestions({
      q,
      connectedQuestions: _connectedQuestions,
    });
    result = [...result, q, ...linkedQuestions];
  }

  return result;
}
/**
 * client-side model: 問卷
 *
 * review 2024-09-23
 * 以後盡量避免此種開發方式，新增一個欄位需要修改ApiAdapter、Model與View
 * 這樣的開發方式不易維護，且容易出錯
 * 因多處地方使用這個model沿用避免出錯
 * 本次新增頁面為
 * 1. 網頁收集方式需判斷CDP類型的問卷，新增CDP相關欄位
 */
class SurveyModel {
  constructor(data) {
    this._data = deepCopy(data);
    this.questions = this._data.questions.map(
      (q, idx) => new QuestionModel(q, { idx, survey: this })
    );
  }

  get id() {
    return this._data.id;
  }

  get title() {
    return this._data.title;
  }

  get audit() {
    return this._data.audit;
  }

  get isBlank() {
    return this._data.isBlank;
  }

  get status() {
    return this._data.status;
  }

  get responseCount() {
    return this._data.responseCount;
  }

  get author() {
    return this._data.author;
  }

  get modified() {
    return this._data.modified;
  }

  get startDate() {
    return this._data.startDate;
  }

  get endDate() {
    return this._data.endDate;
  }

  get upDateTime() {
    return this._data.upDateTime;
  }

  get pageIdxDisplay() {
    if (this._data.pageIdxDisplay === undefined) {
      return false;
    }
    return this._data.pageIdxDisplay;
  }

  get optionIdxDisplay() {
    if (this._data.optionIdxDisplay === undefined) {
      return false;
    }
    return this._data.optionIdxDisplay;
  }

  get questionIdxDisplay() {
    if (this._data.questionIdxDisplay === undefined) {
      return false;
    }
    return this._data.questionIdxDisplay;
  }

  get asteriskDisplay() {
    if (this._data.asteriskDisplay === undefined) {
      return true;
    }
    return this._data.asteriskDisplay;
  }

  get progressDisplay() {
    if (this._data.progressDisplay === undefined) {
      return false;
    }
    return this._data.progressDisplay;
  }

  get progressDisplayDetail() {
    if (this._data.progressDisplayDetail === undefined) {
      return {
        position: "bottom", // "bottom" || "top"
        display: "percentage", // "percentage" || "questions"
      };
    }
    return this._data.progressDisplayDetail;
  }

  get validationCode() {
    if (this._data.validationCode === undefined) {
      return false;
    }
    return this._data.validationCode;
  }

  get oneQuestionPerPage() {
    if (this._data.oneQuestionPerPage === undefined) {
      return false;
    }
    return this._data.oneQuestionPerPage;
  }

  get endingDisplay() {
    if (this._data.endingDisplay === undefined) {
      return true;
    }
    return this._data.endingDisplay;
  }

  get welcomeDisplay() {
    if (this._data.welcomeDisplay === undefined) {
      return false;
    }
    return this._data.welcomeDisplay;
  }

  get themeConfig() {
    const defaultConfig = {
      backgroundColor: "#fff",
      headerImg: "",
      mobileHeaderImg: "",
      styleType: "default", // default 預設, customized 自訂
    };

    if (this._data?.themeConfig) {
      return {
        ...defaultConfig,
        ...this._data.themeConfig,
      };
    }

    return defaultConfig;
  }

  getQuestionIdxDisplay(idx) {
    let displayIdx = 0;
    for (let i = 0; i < idx; i++) {
      if (this.questions[i].isRealQuestion()) {
        displayIdx++;
      }
    }

    return displayIdx + 1;
  }

  getParentQuestionByQuestionId(id) {
    for (const q of this.questions) {
      if (q.type === "SINGLE_CHOICE" || q.type === "MULTIPLE_CHOICE") {
        for (const opt of q.options) {
          if (opt.connectTo !== undefined && opt.connectTo === id) {
            return q;
          }
        }
      }
    }
    return null;
  }

  listConnectedIds() {
    const list = [];

    for (const q of this.questions) {
      if (q.type === "SINGLE_CHOICE" || q.type === "MULTIPLE_CHOICE") {
        for (const opt of q.options) {
          if (opt.connectTo !== undefined) {
            list.push(opt.connectTo);
          }
        }
      }
    }

    return list;
  }

  listConnectableQuestions(question) {
    // Phase 2
    // 子母題子題型擴充: 填空題、 基本資料題、數字總和題
    const CONNECTABLE_TYPES = [
      "SINGLE_CHOICE",
      "MULTIPLE_CHOICE",
      "FILL_IN",
      "BASIC_INFO",
      "NUMERICAL",
    ];

    // 列出已經設定子題之題目
    const connected = this.listConnectedIds()
      .map((id, idx) => this.questions.find(q => q.id === id))
      .filter(q => !!q);

    // 列出可以設定子題之題型，並排除已經設定子題之題目
    const nonConnected = this.questions
      .filter((q, idx) => question.idx < q.idx) // note: 子題只能選擇自己之後的題目
      .filter(q => CONNECTABLE_TYPES.indexOf(q.type) !== -1)
      .filter(q => {
        // 排除已經設定子題之題目
        if (connected.map(q => q.id).indexOf(q.id) === -1) {
          return true;
        }
        return false;
      });

    // note: 若為母題，不可被 connect，否則會有兩層子題
    const nonConnectedAndIsNotParent = nonConnected.filter(q =>
      q.options.every(o => o.connectTo === undefined)
    );

    // 列出目前題目已設定之子題
    const selfConnected = question.options.reduce((acc, opt) => {
      if (opt.connectTo !== undefined) {
        const found = this.questions.find(q => q.id === opt.connectTo);
        found &&
          acc.findIndex(q => q.id === found.id) === -1 &&
          acc.push(found);
      }
      return acc;
    }, []);

    return [...nonConnectedAndIsNotParent, ...selfConnected].sort(
      (a, b) => a.idx - b.idx
    );
  }

  reorderQuestion(srcIdx, destIdx) {
    let surveyClone = deepCopy(this._data);
    let nextQuestions = surveyClone.questions;

    const length = nextQuestions.length;
    srcIdx = parseInt(srcIdx);
    destIdx = parseInt(destIdx);

    if (srcIdx < destIdx) {
      nextQuestions = [
        ...nextQuestions.slice(0, srcIdx),
        ...nextQuestions.slice(srcIdx + 1, destIdx + 1),
        nextQuestions[srcIdx],
        ...nextQuestions.slice(destIdx + 1, length),
      ];
    } else if (srcIdx > destIdx) {
      nextQuestions = [
        ...nextQuestions.slice(0, destIdx),
        nextQuestions[srcIdx],
        ...nextQuestions.slice(destIdx, srcIdx),
        ...nextQuestions.slice(srcIdx + 1, length),
      ];
    }

    surveyClone.questions = nextQuestions;
    return new SurveyModel(surveyClone);
  }

  removeLastQuestion() {
    let surveyClone = deepCopy(this._data);
    let nextQuestions = [...surveyClone.questions];
    nextQuestions.splice(nextQuestions.length - 1, 1);
    surveyClone.questions = nextQuestions;
    return new SurveyModel(surveyClone);
  }

  lastPageNo() {
    const len = this.questions.length;
    if (len === 0) {
      return 0;
    }

    return this.questions[len - 1].PageNo;
  }
  // CDP問卷相關欄位
  get type() {
    return _.get(this._data, "type");
  }
  get cdpValidRegister() {
    return _.get(this._data, "cdpValidRegister");
  }
  get cdpStartTime() {
    return _.get(this._data, "cdpStartTime");
  }
  get cdpEndTime() {
    return _.get(this._data, "cdpEndTime");
  }
  get cdpStatusCode() {
    return _.get(this._data, "cdpStatusCode");
  }
  get cdpSurveyStatus() {
    return _.get(this._data, "cdpSurveyStatus");
  }
}

class QuestionModel {
  constructor(data, { idx, survey }) {
    this._data = deepCopy(data);
    this._idx = idx;
    this._survey = survey;
  }

  get PageNo() {
    // NOTICE: this is from original data-structure
    return this._data.PageNo;
  }

  get id() {
    return this._data.id;
  }

  get idx() {
    return this._idx;
  }

  get type() {
    return this._data.type;
  }

  get content() {
    return this._data.content;
  }

  get contentImage() {
    return this._data.contentImage;
  }

  get contentVideo() {
    return this._data.contentVideo;
  }

  get questionImage() {
    return this._data.questionImage;
  }

  get questionVideo() {
    return this._data.questionVideo;
  }

  get description() {
    return this._data.description;
  }

  get required() {
    return this._data.required;
  }

  get isMandatory() {
    return this._data.isMandatory;
  }

  // FILL_IN
  get answerPlaceholder() {
    return this._data.answerPlaceholder;
  }

  get validateAnswer() {
    return this._data.validateAnswer;
  }

  // BASIC_INFO
  get infoType() {
    return this._data.infoType;
  }

  // IMAGE OR VIDEO
  get url() {
    return this._data.url;
  }

  // MATRIX
  get rows() {
    if (Array.isArray(this._data.rows) && this._data.rows.length > 1) {
      return this._data.rows;
      // if (Array.isArray(this._data.cols) && this._data.cols.length > 1) {
      //   return this._data.cols;
    }
    return ["欄位一", "欄位二"];
  }

  // MATRIX
  get cols() {
    if (Array.isArray(this._data.cols) && this._data.cols.length > 1) {
      return this._data.cols;
      // if (Array.isArray(this._data.rows) && this._data.rows.length > 1) {
      //   return this._data.rows;
    }
    return ["選項一", "選項二"];
  }

  // SINGLE OR MULTIPLE CHOICE
  get options() {
    if (Array.isArray(this._data.options) && this._data.options.length > 1) {
      return this._data.options;
    }

    // 選項拖曳需給定drag id，所以目前在新增題目時，強制給定預設的ID
    return [
      { value: "選項一", id: "option1" },
      { value: "選項二", id: "option2" },
    ];
    // return this._data.options;
  }

  // SINGLE
  get advanced() {
    return (
      this._data.advanced || {
        display: null,
        random: null,
      }
    );
  }

  // NumericalOption
  get numericalOption() {
    if (Array.isArray(this._data.options) && this._data.options.length > 1) {
      return this._data.options;
    }

    // Default 選項改成共用的，這樣再新增題目、新增選項、大量新增時邏輯統一
    return [
      numericalDefaultOption("選項一", 0),
      numericalDefaultOption("選項二", 1),
    ];
  }

  isRealQuestion() {
    return REAL_QUESTIONS.indexOf(this._data.type) > -1;
  }

  getIdxDisplay() {
    if (!this.isRealQuestion()) {
      return "-";
    }

    let displayIdx = 0;
    for (let i = 0; i < this._idx; i++) {
      if (this._survey.questions[i].isRealQuestion()) {
        displayIdx++;
      }
    }
    return displayIdx + 1;
  }

  otherFilledValidate(answer) {
    let _answer =
      typeof answer === "object" && answer !== null ? answer.answer : undefined;

    const _validateOptionOthers = (answer, q) => {
      const otherOptionIdx = q.options.findIndex(o => o.type === "others");
      const othersValue = answer.othersValue;
      const othersOptionConfig = q.options[otherOptionIdx].data;
      if (othersOptionConfig && othersOptionConfig.isMandatory) {
        if (!othersValue) {
          return false;
        }
        if (othersOptionConfig.validateType === "none") {
          return true;
        } else if (othersOptionConfig.validateType === "length") {
          return (
            othersValue.length >= othersOptionConfig.from &&
            othersValue.length <= othersOptionConfig.to
          );
        } else if (othersOptionConfig.validateType === "number") {
          return (
            Number.isInteger(Number(othersValue)) &&
            Number(othersValue) >= othersOptionConfig.from &&
            Number(othersValue) <= othersOptionConfig.to
          );
        }
        return true;
      }
      if (othersOptionConfig && othersOptionConfig.validateType !== "none") {
        if (!othersValue) {
          return true;
        }
        if (othersOptionConfig.validateType === "length") {
          return (
            othersValue.length >= othersOptionConfig.from &&
            othersValue.length <= othersOptionConfig.to
          );
        } else if (othersOptionConfig.validateType === "number") {
          return (
            Number.isInteger(Number(othersValue)) &&
            Number(othersValue) >= othersOptionConfig.from &&
            Number(othersValue) <= othersOptionConfig.to
          );
        }
        return true;
      }
      return true;
    };

    if (this.type === "MULTIPLE_CHOICE") {
      if (this.required) {
        if (
          _answer === null ||
          typeof _answer === "undefined" ||
          _answer?.length === 0
        ) {
          return false;
        }
      }
      let otherValidateResult = true;
      const otherOptionIdx = this.options.findIndex(o => o.type === "others");
      if (Array.isArray(_answer) && _answer.indexOf(otherOptionIdx) !== -1) {
        otherValidateResult = _validateOptionOthers(answer, this);
      }
      return otherValidateResult;
    }
    return true;
  }

  validate(answer) {
    let _answer =
      typeof answer === "object" && answer !== null ? answer.answer : undefined;

    const _validateOptionOthers = (answer, q) => {
      const otherOptionIdx = q.options.findIndex(o => o.type === "others");
      const othersValue = answer.othersValue;
      const othersOptionConfig = q.options[otherOptionIdx].data;
      if (othersOptionConfig && othersOptionConfig.isMandatory) {
        if (!othersValue) {
          return false;
        }
        if (othersOptionConfig.validateType === "none") {
          return true;
        } else if (othersOptionConfig.validateType === "length") {
          return (
            othersValue.length >= othersOptionConfig.from &&
            othersValue.length <= othersOptionConfig.to
          );
        } else if (othersOptionConfig.validateType === "number") {
          return (
            Number.isInteger(Number(othersValue)) &&
            Number(othersValue) >= othersOptionConfig.from &&
            Number(othersValue) <= othersOptionConfig.to
          );
        }
        return true;
      }
      if (othersOptionConfig && othersOptionConfig.validateType !== "none") {
        if (!othersValue) {
          return true;
        }
        if (othersOptionConfig.validateType === "length") {
          return (
            othersValue.length >= othersOptionConfig.from &&
            othersValue.length <= othersOptionConfig.to
          );
        } else if (othersOptionConfig.validateType === "number") {
          return (
            Number.isInteger(Number(othersValue)) &&
            Number(othersValue) >= othersOptionConfig.from &&
            Number(othersValue) <= othersOptionConfig.to
          );
        }
        return true;
      }
      return true;
    };

    if (this.type === "FILL_IN") {
      const regex = /^\s*$/;
      if (!_answer || regex.test(_answer)) {
        // blank answer
        return !this.required;
      } else if (this.validateAnswer) {
        // not blank, need to validate format
        if (this.validateAnswer.type === "text") {
          return (
            _answer.length >= this.validateAnswer.from &&
            _answer.length <= this.validateAnswer.to
          );
        } else if (this.validateAnswer.type === "number") {
          return (
            Number.isInteger(Number(_answer)) &&
            Number(_answer) >= this.validateAnswer.from &&
            Number(_answer) <= this.validateAnswer.to
          );
        } else {
          return true;
        }
      }
    } else if (this.type === "BASIC_INFO") {
      if (!this.required) {
        if (_answer === undefined || _answer === "" || _answer === null) {
          return true;
        }
      }

      if (this.infoType === "id") {
        return FormatValidator.identityNumber(_answer);
      } else if (this.infoType === "email") {
        return FormatValidator.email(_answer);
      } else if (this.infoType === "phone") {
        return FormatValidator.phone(_answer);
      } else if (this.infoType === "hn") {
        return FormatValidator.hn(_answer);
      } else if (this.infoType === "md") {
        return FormatValidator.md(_answer);
      }
    } else if (this.type === "SINGLE_CHOICE") {
      if (this.required) {
        if (_answer === null || typeof _answer === "undefined") {
          return false;
        }
      }
      const otherOptionIdx = this.options.findIndex(o => o.type === "others");
      if (_answer === otherOptionIdx && this.options[otherOptionIdx].data) {
        let resp = _validateOptionOthers(answer, this);
        return resp;
      }
      // if (this.isMandatory) {
      //   const otherOptionIdx = this.options.findIndex(o => o.type === "others");
      //   if (_answer === otherOptionIdx && this.options[otherOptionIdx].data) {
      //     let resp = _validateOptionOthers(answer, this);
      //     return resp;
      //   }
      //   // return typeof _answer !== "undefined";
      // }
      return true;
    } else if (this.type === "MULTIPLE_CHOICE") {
      if (this.required) {
        if (
          _answer === null ||
          typeof _answer === "undefined" ||
          _answer?.length === 0
        ) {
          return false;
        }
      }
      let otherValidateResult = true;
      const otherOptionIdx = this.options.findIndex(o => o.type === "others");
      if (Array.isArray(_answer) && _answer.indexOf(otherOptionIdx) !== -1) {
        otherValidateResult = _validateOptionOthers(answer, this);
      }
      // if (
      //   this.isMandatory &&
      //   Array.isArray(_answer) &&
      //   _answer.indexOf(otherOptionIdx) !== -1
      // ) {
      //   otherValidateResult = _validateOptionOthers(answer, this);
      // }
      if (Array.isArray(_answer) && _answer.length > 0) {
        // has answers
        // 其他，填答驗證，不明原因為undefined
        if (this.advanced?.limit) {
          return (
            _answer.length >= this.advanced.limit.from &&
            _answer.length <= this.advanced.limit.to &&
            otherValidateResult
          );
        }
        return true && otherValidateResult;
      } else {
        return !this.required && otherValidateResult;
      }
      // } else if (this.type === "SINGLE_CHOICE" && this.required) {
      //   const otherOptionIdx = this.options.findIndex(o => o.type === "others");
      //   const isOtherSelected = _answer === otherOptionIdx;
      //   if (isOtherSelected && this.options[otherOptionIdx].data) {
      //     let resp = _validateOptionOthers(answer, this);
      //     return resp;
      //   }
      //   return typeof _answer !== "undefined";
      // } else if (this.type === "MULTIPLE_CHOICE") {
      //   let otherValidateResult = true;
      //   const otherOptionIdx = this.options.findIndex(o => o.type === "others");
      //   const isOtherSelected = _answer === otherOptionIdx;
      //   if (isOtherSelected && this.options[otherOptionIdx].data) {
      //     otherValidateResult = _validateOptionOthers(answer, this);
      //   }
      //   if (Array.isArray(_answer) && _answer.length > 0) {
      //     // has answers
      //     // 其他，填答驗證，不明原因為undefined
      //     if (this.advanced?.limit) {
      //       return (
      //         _answer.length >= this.advanced.limit.from &&
      //         _answer.length <= this.advanced.limit.to &&
      //         otherValidateResult
      //       );
      //     }
      //     return true && otherValidateResult;
      //   } else {
      //     return !this.required && otherValidateResult;
      //   }
    } else if (this.type === "MATRIX" && this.required) {
      if (Array.isArray(_answer)) {
        return _answer.length === this.rows.length;
      }
      return false;
    } else if (this.type === "NUMERICAL") {
      // 檢核必填
      if (this.required) {
        if (
          _answer === null ||
          typeof _answer === "undefined" ||
          _answer?.length === 0
        ) {
          return false;
        } else {
          const find = _answer.find(
            item => item.value === null || typeof item === "undefined"
          );
          if (find) return false;
        }
      }

      if (_answer) {
        // 數值檢核
        const valueCheck = _answer.find(a => {
          const option = this.options.find(o => o.id == a.id);
          if (a.value > option.OptionMinLimit) return true;
        });
        if (valueCheck) return false;
      }

      if (_answer) {
        // 總和檢核
        let totalCheck = 0;
        _answer.forEach(a => (totalCheck += a.value));
        if (this._data.numericalTotal != totalCheck) return false;
      }
    }
    return true;
  }

  static getInitialData(questionType) {
    let nextQuestion = null;

    if (questionType === "PAGE") {
      nextQuestion = {
        type: "PAGE",
      };
    } else if (questionType === "SINGLE_CHOICE") {
      nextQuestion = {
        type: "SINGLE_CHOICE",
        content: [],
        description: "Test description",
        options: [],
        advanced: {
          display: null, // {desktop: 1, mobile: 1}
          random: null, // {excludeOthers: true}
        },
      };
    } else if (questionType === "MULTIPLE_CHOICE") {
      nextQuestion = {
        type: "MULTIPLE_CHOICE",
        content: [],
        description: "Test description",
        options: [
          { value: "選項一" },
          { value: "選項二" },
          { value: "選項三" },
        ],
        advanced: {
          limit: null, // 選項數量限制 {from: 2, to: 6}
          display: null, // {desktop: 1, mobile: 1}
          random: null, // {excludeOthers: true}
        },
      };
    } else if (questionType === "FILL_IN") {
      nextQuestion = {
        type: "FILL_IN",
        content: [],
        description: "Test description",
        // options: [],
      };
    } else if (questionType === "MATRIX") {
      nextQuestion = {
        type: "MATRIX",
        content: [],
        description: "Test description",
      };
    } else if (questionType === "TEXT") {
      nextQuestion = {
        type: "TEXT",
        content: [],
      };
    } else if (questionType === "BASIC_INFO") {
      nextQuestion = {
        type: "BASIC_INFO",
        content: [],
        description: "Test description",
      };
    } else if (questionType === "VIDEO") {
      nextQuestion = {
        type: "VIDEO",
        content: [],
        description: "Test description",
      };
    } else if (questionType === "IMAGE") {
      nextQuestion = {
        type: "IMAGE",
        content: [],
        description: "Test description",
      };
    } else if (questionType === "NUMERICAL") {
      nextQuestion = {
        type: "NUMERICAL",
        content: [],
        description: "Test description",
        options: [
          { value: "選項一" },
          { value: "選項二" },
          { value: "選項三" },
        ],
      };
    } else {
      throw new Error(`incorrect questionType ${questionType}`);
    }
    return { id: `${new Date().getTime()}`, ...nextQuestion };
  }

  get numericalStyle() {
    return this._data.numericalStyle;
  }

  get numericalTotal() {
    return this._data.numericalTotal;
  }

  get decimalPlace() {
    return this._data.decimalPlace;
  }

  get unit() {
    return this._data.unit;
  }

  // 是否為子題
  get isChild() {
    return this._survey.getParentQuestionByQuestionId(this.id) !== null;
  }

  // 取得母題 ID
  get getParentQuestion() {
    return this._survey.getParentQuestionByQuestionId(this.id);
  }
}

class LogicRuleModel {
  static createData(type) {
    const base = {
      type, // jump, prevent, forceQuit, preventByQuestion
      constraint: "any", // any, all
      rules: [],
      action: type === "prevent" ? [] : null,
      // 因為 跨題選項(type == 4) 設定 題目 + 選項，所以新增屬性，存放選項資訊
      blockOptionList: [],
    };

    return {
      id: `${new Date().getTime()}`,
      ...base,
    };
  }

  constructor(data) {
    this._data = data;
  }

  get id() {
    return this._data.id;
  }

  get type() {
    return this._data.type;
  }

  get constraint() {
    return this._data.constraint || "any";
  }

  get rules() {
    return this._data.rules;
  }

  get action() {
    return this._data.action;
  }

  // 因為 跨題選項(type == 4) 設定 題目 + 選項，所以新增屬性，存放選項資訊
  get blockOptionList() {
    return this._data.blockOptionList;
  }
}

class QuestionStatModel {
  constructor(data, { idx }) {
    this._data = deepCopy(data);
    this._idx = idx;
  }

  get questionId() {
    return this._data.questionId;
  }

  get questionTitle() {
    return this._data.questionTitle;
  }

  get respCount() {
    return this._data.respCount;
  }

  get x_Axis() {
    return this._data.x_Axis;
  }

  get optionStats() {
    // SINGLE_CHOICE || MULTIPLE_CHOICE
    return this._data.optionStats || [];
  }

  get optionMatrixStats() {
    // MATRIX
    return this._data.optionMatrixStats || [];
  }

  get cols() {
    // MATRIX
    return this._data.cols || [];
  }

  get rows() {
    // MATRIX
    return this._data.rows || [];
  }
}
class UserModel {
  constructor(data) {
    this._data = data;
  }

  get username() {
    return this._data.username;
  }

  get id() {
    return this._data.id;
  }

  get name() {
    return this._data.name;
  }

  get created() {
    return this._data.created;
  }

  get role() {
    return this._data.role; // admin | staff
  }

  get RoleId() {
    return this._data.RoleId; // 1:admin | 2:staff
  }

  get lastLoginAt() {
    return this._data.lastLoginAt;
  }
}

class TemplateModel {
  constructor(data) {
    this._data = data;
  }

  get id() {
    return this._data.id;
  }

  get title() {
    return this._data.title;
  }

  get questionCount() {
    return this._data.questionCount;
  }
}
/** 角色類型 */
const RoleID = {
  // Admin:系統管理員
  SystemAdmin: 1,
  // SystemUser:行銷人員
  SystemUser: 2,
  // SystemContractUser:第三方行銷人員
  SystemContractUser: 3,
  // Owner:團隊擁有者 101
  TeamOwner: 101,
  // Editor:團隊編輯者 102
  TeamEditor: 102,
  // Viewer:團隊檢視者 103
  TeamViewer: 103,
};
export {
  SurveyModel,
  QuestionModel,
  LogicRuleModel,
  QuestionStatModel,
  UserModel,
  TemplateModel,
  RoleID,
};
