import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as Ant from "antd";
import * as Icon from "./Icon";
import { ExclamationCircleFilled } from "@ant-design/icons";
import DragIcon from "../svg-icons/actions-navigation-dragable-24.svg";
import Widget, { Color, Font, FontSize } from "./Widget";
import QuestionBottomBar from "./QuestionBottomBar";
import * as Survey from "../Contexts/SurveyContext";
import AddManyModal from "./AddManyModal";
import AddOtherModal from "./AddOtherModal";
import RichTextEditor from "./RichTextEditor";
import AdvEditingModal from "./AdvEditingModal";
import AddImageModal from "./AddImageModal";
import AddVideoModal from "./AddVideoModal";
import * as SvgIcon from "./SvgIcon";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from "lodash";

const TABS = [
  {
    key: 0,
    display: "編輯",
  },
  {
    key: 1,
    display: "進階",
  },
];

const NumericalStyleOptions = [
  {
    key: 0,
    display: "滑桿",
  },
  {
    key: 1,
    display: "輸入欄",
  },
];

const DecimalPlaceOptions = [
  {
    key: 0,
    display: "0",
  },
  {
    key: 1,
    display: "1",
  },
  ,
  {
    key: 2,
    display: "2",
  },
  ,
  {
    key: 3,
    display: "3",
  },
];

const defaultOption = (value = "選項", suffix = 0) => ({
  type: "default",
  value: value,
  id: `${Date.now().toString()}_${suffix.toString()}`,
  optionMinLimit: 0,
  optionMinLimitError: false, // 最小值錯誤flag
  optionMaxLimit: null,
  optionMaxLimitError: false, // 最大值錯誤flag
});

function EditOnHover(props) {
  return (
    <DisplayImageWrapper extraCss={props.extraCss || ""}>
      <div className="mask">
        <div className="action-items">
          <div className="action-item" onClick={props.onEdit}>
            <SvgIcon.EditMode color={Color.GreyWhite} size={30} />
          </div>
          <div className="action-item" onClick={props.onDelete}>
            <SvgIcon.Delete color={Color.GreyWhite} size={30} />
          </div>
        </div>
      </div>
      {props.children}
    </DisplayImageWrapper>
  );
}

function Comp({ type, survey, question, questionIdx, ...props }) {
  const [activeTab, setActiveTab] = useState(0);
  const [showAddManyModal, setShowAddManyModal] = useState(false);
  const [showAddImageModal, setShowAddImageModal] = useState(false);
  const [showAddVideoModal, setShowAddVideoModal] = useState(false);
  const [showAdvEditingModal, setShowAdvEditingModal] = useState(false);
  const [selectedImgUrl, setSelectedImgUrl] = useState();
  const [currOptionIdx, setCurrOptionIdx] = useState(0);
  const [currOptionUrl, setCurrOptionUrl] = useState("");
  const [contentImage, setContentImage] = useState(question.contentImage);
  const [editingContentImage, setEditingContentImage] = useState(false);
  const [contentVideo, setContentVideo] = useState(question.contentVideo);
  const [editingContentVideo, setEditingContentVideo] = useState(false);

  const [questionDescription, setQuestionDescription] = useState(
    question.description
  );
  const [options, setOptions] = useState(
    JSON.parse(JSON.stringify(question.numericalOption))
  );
  const [required, setRequired] = useState(question.required || false);
  const [onFocusIdx, setOnFocusIdx] = useState("");
  const [numericalStyle, setNumericalStyle] = useState(
    question.numericalStyle || 0
  );
  const [numericalTotal, setNumericalTotal] = useState(question.numericalTotal);
  const [numericalTotalError, setNumericalTotalError] = useState(false);
  const [numericalTotalHelpText, setNumericalTotalHelpText] = useState(null);
  const [numericalTotalCurrent, setNumericalTotalCurrent] = useState(
    calTotal(options)
  );

  const [decimalPlace, setDecimalPlace] = useState(question.decimalPlace || 0);
  const [unit, setUnit] = useState(question.unit || "");
  const [onDragIdx, setOnDragIdx] = useState(""); // 記錄onDragStart時，被選取的物件id，用於呈現icon
  const [emptyItem, setEmptyItem] = useState([]); // 記錄那些option最大值為空
  const [difference, setDifference] = useState(null);

  // 新增選項
  const _addNewOption = insertIdx => {
    options.splice(insertIdx + 1, 0, defaultOption());
    setOptions([...options]);
  };

  // 大量新增選項
  const _appendMultipleOptions = textList => {
    setOptions([
      ...options,
      ...textList.map((value, idx) => defaultOption(value, idx)),
    ]);
  };

  // 移除選項
  const _removeQuestionOption = removedIdx => {
    if (options.length <= 2) {
      Ant.Modal.warning({
        title: "不可刪除提醒",
        content: "最少需要兩個選項",
        okText: "確定",
        icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
      });
      return;
    }
    setOptions(options.filter((o, idx) => idx !== removedIdx));
  };

  // 編輯選項
  const _editQuestionOption = (idx, value, field = "value") => {
    let _options = [...options];
    const option = _options[idx];
    option[field] = value;

    check(option, field);

    // 刪除此選項
    if (typeof value == "undefined") {
      delete _options[idx][field];
      if (typeof question != "undefined") delete question.options[idx][field];
    }

    setOptions(_options);
    calTotal(_options);
  };

  const _editQuestionOptionImage = (idx, image) => {
    let _options = [...options];
    _options[idx] = { ..._options[idx], mediaType: "image", url: image };
    setOptions(_options);
  };

  const _editQuestionOptionVideo = (idx, video) => {
    let _options = [...options];
    _options[idx] = { ..._options[idx], mediaType: "video", url: video };
    setOptions(_options);
  };

  //合計值填入時，記錄哪先最大值欄位為空，在onChang時，就固定更新那幾行
  const _remberWhichIsEmpty = e => {
    let empty = [];
    options.forEach((item, idx) => {
      if (
        item.optionMaxLimit == null ||
        item.optionMaxLimit == "" ||
        item.optionMaxLimit == "undefined"
      )
        empty.push(idx);
    });

    setEmptyItem(empty);
  };

  //滑鼠離開合計值時，就忘記哪幾行為空，清空資料
  const _forgetWhichIsEmpty = e => {
    setEmptyItem([]);
  };

  // 編輯合計值時，自動帶入最大值
  const _editNumericalTotal = numericalTotal => {
    if (!_.isNumber(numericalTotal) || numericalTotal < 0) {
      console.log("numericalTotal", numericalTotal);
      setNumericalTotalError(true);
      setNumericalTotalHelpText("需輸入大於 0 數值");
    } else {
      setNumericalTotalError(false);
      setNumericalTotalHelpText(null);
    }

    let _options = [...options];
    emptyItem.forEach(item => {
      _options[item].optionMaxLimit = numericalTotal;
    });

    _options.forEach(option => {
      if (option.optionMaxLimit > numericalTotal) {
        option.optionMaxLimitError = true;
        option.optionMaxLimitHelpText = "需小於合計值";
      } else {
        option.optionMaxLimitError = false;
        option.optionMaxLimitHelpText = "";
      }
    });

    setNumericalTotal(numericalTotal);
    setOptions(_options);
  };

  const _revert = async () => {
    props.editorProps.resetContent();
    setQuestionDescription(question.description);
    setContentImage(question.contentImage);
    setContentVideo(question.contentVideo);
    // setOptions(question.options);
    setOptions(JSON.parse(JSON.stringify(question.options)));
    props.setEditingIdx(null);
    setRequired(question.required || false);

    if (Survey.Actions.shouldDeleteOnCancel()) {
      props.setSurvey(survey.removeLastQuestion());
      await Survey.Actions.deleteQuestionById(survey.id, questionIdx, question);
      props.setUpdater(props.updater + 1);
    }
  };

  const _save = async () => {
    // 檢核題目欄位是否空白
    if (props.editorProps.isContentBlank()) {
      Ant.Modal.warning({
        title: "欄位不可為空",
        content: "題目欄位不可為空",
        okText: "確定",
        icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
      });
      return;
    }

    // 檢核
    const _options = [...options];
    let totalMaxLimitCount = 0;
    let isError = false;
    let isMaxError = false; // 最大值一般檢核是否有錯誤

    for (let idx = 0; idx < _options.length; idx++) {
      const option = _options[idx];
      // 檢核最小值
      if (check(option, "optionMinLimit")) isError = true;
      // 檢核最大值
      if (check(option, "optionMaxLimit")) {
        isError = true;
        isMaxError = true;
      }
    }

    // 當最大值通過一般檢核時，才會針對總和值進行檢核
    if (!isMaxError) {
      _options.map(option => {
        const maxValue = option["optionMaxLimit"];
        totalMaxLimitCount += maxValue;
      });
      const diff = _.subtract(numericalTotal, totalMaxLimitCount);
      if (diff > 0) {
        _options.map(option => {
          const field = "optionMaxLimit";
          option[field + "Error"] = true;
          option[field + "HelpText"] = "需增加數值";
          setDifference(diff);
        });
        isError = true;
      } else {
        setDifference(null);
      }
    }

    // 如果有發生錯誤，則呈顯於畫面
    if (isError) {
      setOptions(_options);
    }

    // 都通過檢核才會發送API
    if (!isError) {
      const hide = Ant.message.loading("儲存中", 0);
      try {
        await Survey.Actions.updateQuestionById(
          survey.id,
          questionIdx,
          question,
          {
            title: "",
            content: props.editorProps.getContent(),
            contentImage,
            contentVideo,
            description: questionDescription,
            unit: unit,
            numericalStyle,
            numericalTotal,
            decimalPlace,
            options,
            required,
          }
        );
        props.setUpdater(props.updater + 1);
        props.setEditingIdx(null);
      } catch (ex) {}
      hide();
    }
  };

  function calTotal(_options) {
    // 檢核
    let totalMaxLimitCount = 0;
    for (let idx = 0; idx < _options.length; idx++) {
      const option = _options[idx];
      totalMaxLimitCount += option["optionMaxLimit"];
    }
    if (setNumericalTotalCurrent !== undefined) {
      setNumericalTotalCurrent(totalMaxLimitCount);
    }

    return totalMaxLimitCount;
  }

  function isNum(val) {
    // 2022-09-08 ALVIN 0判斷為數字
    if (val === 0) return true;
    return !isNaN(val);
  }

  function check(option, field) {
    const minValue = option["optionMinLimit"];
    const maxValue = option["optionMaxLimit"];
    let isError = false;
    let helpText = "";

    // 通用檢核
    if (field === "optionMinLimit" || field === "optionMaxLimit") {
      if (
        (!option[field] && !isNum(option[field])) ||
        option[field] < 0 ||
        option[field] === null
      ) {
        isError = true;
        helpText = "需輸入大於0數值";
      }

      if (field === "optionMinLimit") {
        if (maxValue != null && minValue > maxValue) {
          isError = true;
          helpText = "需小於最大值";
        }
      } else if (field === "optionMaxLimit") {
        if (numericalTotal != null && maxValue > numericalTotal) {
          isError = true;
          helpText = "需小於合計值";
        }

        if (minValue > maxValue) {
          isError = true;
          helpText = "需大於最小值";
        }

        if (maxValue === 0) {
          isError = true;
          helpText = "需輸入大於0數值";
        }
      }
      if (option[field] < 0) {
        isError = true;
        helpText = "不得輸入負數";
      }
    }

    option[field + "Error"] = isError;
    option[field + "HelpText"] = helpText;
    return isError;
  }

  // 拖曳結束事件
  const _onDragEnd = result => {
    props.setIsDragging(false);
    setOnDragIdx("");
    const srcIdx = parseInt(result.source?.index);
    const destIdx = parseInt(result.destination?.index);

    if (destIdx == srcIdx) return;

    let tempOpts = [...options];
    let tempOpt = tempOpts[srcIdx];
    tempOpts.splice(srcIdx, 1);
    tempOpts.splice(destIdx, 0, tempOpt);
    setOptions(tempOpts);
  };

  return (
    <>
      <Wrapper>
        <div className="tabs">
          {TABS.map((tab, idx) => (
            <div
              key={idx}
              className={`tab${tab.key === activeTab ? " active" : ""}`}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.display}
            </div>
          ))}
        </div>
        {activeTab === TABS[0].key && (
          <>
            <div className="tab-content">
              <div className="content-block">
                <div className="question-number" style={{ marginTop: 32 }}>
                  {question.getIdxDisplay()}
                </div>
                <div className="content">
                  <div className="question-info">
                    {!showAdvEditingModal ? (
                      <RichTextEditor
                        editor={props.editorProps.editor}
                        onMoreClick={() => setShowAdvEditingModal(true)}
                        onAddVideoClick={() => {
                          setEditingContentVideo(true);
                          setShowAddVideoModal(true);
                        }}
                        onAddImageClick={() => {
                          setSelectedImgUrl();
                          setEditingContentImage(true);
                          setShowAddImageModal(true);
                        }}
                        basic
                        styles={{ minHeight: 72 }}
                      />
                    ) : (
                      <div
                        style={{
                          height: 150,
                          padding: 10,
                          backgroundColor: "#efefef",
                          marginTop: 32,
                        }}
                      >
                        進階編輯中...
                      </div>
                    )}
                    <input
                      className="description"
                      value={questionDescription}
                      placeholder="備註說明，顯示在題目下方的作答提示"
                      onChange={e => setQuestionDescription(e.target.value)}
                    />
                  </div>

                  {contentImage && (
                    <EditOnHover
                      extraCss={"margin-top: 10px; margin-right: 10px;"}
                      onEdit={() => {
                        setSelectedImgUrl(contentImage);
                        setEditingContentImage(true);
                        setShowAddImageModal(true);
                      }}
                      onDelete={() => {
                        setSelectedImgUrl();
                        setContentImage(null);
                        setShowAddImageModal(false);
                      }}
                    >
                      <img
                        src={contentImage}
                        onError={e => {
                          e.target.src = "/images/image-placeholder.png";
                          e.target.onError = null;
                        }}
                        style={{
                          width: 133,
                          height: 100,
                          objectFit: "contain",
                          border: "0px solid #979797",
                          display: "block",
                          marginTop: 10,
                        }}
                        alt="content illustration"
                      />
                    </EditOnHover>
                  )}

                  {contentVideo && (
                    <EditOnHover
                      extraCss={"margin-top: 10px;"}
                      onEdit={() => {
                        setEditingContentVideo(true);
                        setShowAddVideoModal(true);
                      }}
                      onDelete={() => {
                        setContentVideo(null);
                        setShowAddVideoModal(false);
                      }}
                    >
                      <iframe
                        width="178"
                        height="100"
                        frameBorder="no"
                        border="0"
                        title={`YouTube Player for content video`}
                        style={{ border: "0px solid #979797" }}
                        src={contentVideo}
                      />
                    </EditOnHover>
                  )}

                  <div
                    className="question-info"
                    style={{ margin: "32px 0 0 0" }}
                  >
                    <Widget.FlexRow style={{ justifyContent: "space-between" }}>
                      <div style={{ width: 358 }}>
                        <Widget.FlexRow style={{ marginBottom: 8 }}>
                          <Font.Body
                            style={{
                              color: Color.GreyBlack_65,
                              marginRight: 8,
                            }}
                          >
                            合計
                          </Font.Body>
                          <Ant.Tooltip title="此題目選項合計可以填的最大值，如時間為24、百分比為100。">
                            <SvgIcon.BasicInfoOutline color="#ffc53d" />
                          </Ant.Tooltip>
                        </Widget.FlexRow>
                        <Ant.InputNumber
                          style={{ width: "100%" }}
                          value={numericalTotal}
                          onFocus={_remberWhichIsEmpty}
                          onBlur={_forgetWhichIsEmpty}
                          onChange={_editNumericalTotal}
                        />
                        <div className="helpText">
                          {numericalTotalError ? numericalTotalHelpText : ""}
                        </div>
                      </div>

                      <div style={{ width: 358 }}>
                        <Font.Body
                          style={{
                            color: Color.GreyBlack_65,
                            marginBottom: 8,
                            height: 24,
                          }}
                        >
                          單位
                        </Font.Body>
                        <Ant.Input
                          placeholder="例：％、元、小時、分鐘...等"
                          value={unit}
                          onChange={e => setUnit(e.target.value)}
                        />
                        <div className="helpText"></div>
                      </div>
                    </Widget.FlexRow>
                  </div>
                  <Ant.Divider style={{ margin: "0px 0 16px 0" }} />
                  <div className="total-tip">
                    <a className="blue">{`選項最大值的總和需大於等於合計值。目前最大值的總和為 ${numericalTotalCurrent}`}</a>
                    {difference ? (
                      <>
                        <a className="blue">，</a>
                        <a className="red">{`需增加 ${difference}`}</a>
                      </>
                    ) : null}
                  </div>
                  <Widget.FlexRow>
                    <div style={{ marginLeft: 374 }}>最小值</div>
                    <div style={{ marginLeft: 82 }}>最大值</div>
                  </Widget.FlexRow>
                </div>
              </div>
              <div className="content">
                <DragDropContext
                  onDragStart={e => {
                    props.setIsDragging(true);
                    setOnDragIdx(e.draggableId);
                    setOnFocusIdx("");
                  }}
                  onDragEnd={_onDragEnd}
                >
                  <Droppable droppableId={question.id}>
                    {provided => (
                      <div
                        className="options"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        // style={{ maxWidth: 732 }}
                      >
                        {options.map((o, idx) =>
                          o.type !== "others" ? (
                            <Draggable
                              key={o.id ? o.id : idx.toString()}
                              draggableId={o.id ? o.id : idx.toString()}
                              index={idx}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  key={idx}
                                >
                                  <DragWrapper isDragging={snapshot.isDragging}>
                                    <div
                                      className={
                                        onFocusIdx === idx
                                          ? "option focus"
                                          : "option"
                                      }
                                    >
                                      <div className="option-drag-block">
                                        <DragIcon
                                          className={
                                            onDragIdx == o.id
                                              ? "option-drag-icon focus"
                                              : "option-drag-icon"
                                          }
                                        />
                                      </div>
                                      <div className="option-wrapper">
                                        {snapshot.isDragging || (
                                          <span className="option-wrapper-idx">
                                            {idx + 1}.{" "}
                                          </span>
                                        )}
                                        <input
                                          placeholder={"選項"}
                                          className="option-input"
                                          value={o.value}
                                          onFocus={() => setOnFocusIdx(idx)}
                                          onBlur={() => setOnFocusIdx("")}
                                          onChange={e =>
                                            _editQuestionOption(
                                              idx,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>

                                      <div className="min-block input-block">
                                        <Ant.Form.Item
                                          validateStatus={
                                            o.optionMinLimitError ? "error" : ""
                                          }
                                          help={
                                            o.optionMinLimitHelpText
                                              ? o.optionMinLimitHelpText
                                              : ""
                                          }
                                        >
                                          <Ant.InputNumber
                                            onChange={e =>
                                              _editQuestionOption(
                                                idx,
                                                e,
                                                "optionMinLimit"
                                              )
                                            }
                                            value={o.optionMinLimit}
                                          />
                                        </Ant.Form.Item>
                                      </div>
                                      <div className="max-block input-block">
                                        <Ant.Form.Item
                                          validateStatus={
                                            o.optionMaxLimitError ? "error" : ""
                                          }
                                          help={
                                            o.optionMaxLimitHelpText
                                              ? o.optionMaxLimitHelpText
                                              : ""
                                          }
                                        >
                                          <Ant.InputNumber
                                            onChange={e =>
                                              _editQuestionOption(
                                                idx,
                                                e,
                                                "optionMaxLimit"
                                              )
                                            }
                                            value={o.optionMaxLimit}
                                          />
                                        </Ant.Form.Item>
                                      </div>
                                      <div>
                                        <Icon.Remove
                                          size={FontSize.largeTitle}
                                          color={Color.GreyBlack}
                                          onClick={() =>
                                            _removeQuestionOption(idx)
                                          }
                                          style={{
                                            marginRight: 16,
                                            cursor: "pointer",
                                          }}
                                        />
                                        <Icon.Add
                                          size={FontSize.largeTitle}
                                          color={Color.GreyBlack}
                                          onClick={() => _addNewOption(idx)}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </DragWrapper>
                                </div>
                              )}
                            </Draggable>
                          ) : null
                        )}
                        {provided.placeholder}
                        <div className="option">
                          <div
                            className="add-massive-button"
                            onClick={() => {
                              setShowAddManyModal(true);
                            }}
                          >
                            新增大量選項
                          </div>
                        </div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </>
        )}
        {activeTab === TABS[1].key && (
          <>
            <div className="tab-content">
              <Widget.FlexCol
                style={{ paddingLeft: 36, marginTop: 16, width: 418 }}
              >
                <div style={{ marginBottom: 32 }}>
                  <Font.Body style={{ marginBottom: 8 }}>
                    四捨五入至小數第幾位
                  </Font.Body>
                  <Ant.Select
                    height={32}
                    value={decimalPlace}
                    onChange={e => setDecimalPlace(e)}
                    style={{ width: "100%" }}
                  >
                    {DecimalPlaceOptions.map((item, idx) => (
                      <Ant.Select.Option key={idx} value={item.key}>
                        {item.display}
                      </Ant.Select.Option>
                    ))}
                  </Ant.Select>
                </div>

                <div style={{ marginBottom: 162 }}>
                  <Font.Body style={{ marginBottom: 8 }}>填答樣式</Font.Body>
                  <Ant.Select
                    height={32}
                    value={numericalStyle}
                    onChange={e => setNumericalStyle(e)}
                    style={{ width: "100%" }}
                  >
                    {NumericalStyleOptions.map((item, idx) => (
                      <Ant.Select.Option key={idx} value={item.key}>
                        {item.display}
                      </Ant.Select.Option>
                    ))}
                  </Ant.Select>
                </div>
              </Widget.FlexCol>
            </div>
          </>
        )}

        {showAddImageModal && (
          <AddImageModal
            selectedImgUrl={selectedImgUrl}
            visible={true}
            insertImage={url => {
              if (editingContentImage) {
                setContentImage(url);
                return;
              }
              _editQuestionOptionImage(currOptionIdx, url);
            }}
            onCancel={() => {
              setShowAddImageModal(false);
              setEditingContentImage(false);
            }}
          />
        )}

        {showAddVideoModal && (
          <AddVideoModal
            visible={true}
            isContentVideo={editingContentVideo}
            contentUrl={contentVideo}
            currUrl={currOptionUrl}
            insertVideo={videoId => {
              const url = `https://www.youtube.com/embed/${videoId}`;
              if (editingContentVideo) {
                setContentVideo(url);
                return;
              }
              _editQuestionOptionVideo(currOptionIdx, url);
            }}
            onCancel={() => {
              setShowAddVideoModal(false);
              setEditingContentVideo(false);
            }}
          />
        )}

        {showAdvEditingModal && (
          <AdvEditingModal
            editorProps={props.editorProps}
            editor={props.editorProps.editor}
            onCancel={() => setShowAdvEditingModal(false)}
          />
        )}

        <AddManyModal
          visible={showAddManyModal}
          appendMultipleOptions={_appendMultipleOptions}
          onCancel={() => setShowAddManyModal(false)}
        />
      </Wrapper>
      <QuestionBottomBar
        required={required}
        type={question.type}
        onCheck={checked => setRequired(checked)}
        onCancel={_revert}
        onSave={_save}
      />
    </>
  );
}

const Wrapper = styled.div`
  margin-bottom: -20px;

  & .hint {
    padding: 4px;
    border-radius: 2px;
    border: 1px solid #bababa;
    color: #bababa;
  }

  & .tabs {
    height: 46px;
    padding-left: 32px;
    color: #585858;
    display: flex;

    & .tab {
      display: flex;
      font-size: ${FontSize.body}px;
      align-items: center;
      margin-right: 20px;
      cursor: pointer;
      border-top: 3px solid transparent;
    }

    & .tab.active {
      color: #534ab3;
      border-top: 3px solid #534ab3;
    }
  }

  & .tab-content {
    display: flex;

    // 選項拖曳多切版型
    flex-direction: column;

    // 選項拖曳多切版型
    & .content-block {
      display: flex;
      width: 100%;
    }

    & .question-number {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: ${FontSize.body}px;
      color: ${Color.GreyWhite};
      background-color: ${Color.GreyBlack};
    }

    & .grey-square {
      width: 14px;
      height: 16px;
      background-color: ${Color.WhiteGrey3};
      margin-right: 6px;
    }

    & .content {
      padding-right: 32px;

      & .question-info {
        display: flex;
        flex-direction: column;
        max-width: 732px;

        & .title {
          padding: 16px;
          font-size: ${FontSize.head}px;
          color: ${Color.GreyBlack_85};
          margin-bottom: 10px;
          border-radius: 0px;
          border: none;
          border-bottom: 2px solid #bababa;
          background: #f4f4f4;
          box-shadow: none;
          outline: none;

          &:focus {
            outline: none;
          }
        }

        & .description {
          border-radius: 0px;
          border: none;
          font-size: ${FontSize.caption}px;
          color: ${Color.GreyBlack_45};
          border-bottom: 1px solid #8d8d8d;
          background: #f4f4f4;
          box-shadow: none;
          outline: none;
          padding: 10px 16px;
          margin-top: 8px;

          &:focus {
            outline: none;
          }
        }
      }

      & .options {
        & .option {
          display: flex;
          align-items: center;
          font-size: ${FontSize.body}px;
          padding: 8px 0;
          & > .action-buttons {
            display: none;
          }

          .option-wrapper {
            width: 358px;
            margin-right: 10px;
            display: flex;
            align-items: center;
          }

          .min-block {
            margin-right: 14px;
          }

          .max-block {
            margin-right: 56px;
          }

          .input-block {
            width: 110px;
            height: 32px;

            .ant-input-number {
              width: 100%;
            }
          }

          &:hover {
            & > .option-input {
              box-shadow: 0px 1px ${Color.Purple};
            }

            & > .action-buttons {
              display: flex;
              align-items: center;
            }

            .option-wrapper {
              border-bottom: 1px solid #979797;
            }

            .option-drag-block {
              .option-drag-icon {
                display: block;
              }
            }
          }

          &.focus {
            .option-wrapper {
              border-bottom: 1px solid ${Color.Purple};
            }
          }

          & .option-input {
            color: ${Color.GreyBlack_85};
            flex: 1;
            border: none;
            background-image: none;
            background-color: transparent;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            margin-left: 5px;

            box-shadow: none;
            outline: none;
            padding: 2px 0px;

            &:focus + .action-buttons {
              display: flex;
              align-items: center;
            }
          }

          & .add-new-option-button {
            cursor: pointer;
            color: ${Color.GreyBlack_45};
            margin-left: 5px;
            margin-right: 5px;
          }

          & .add-massive-button {
            cursor: pointer;
            margin-left: 5px;
            margin-right: 5px;
            color: ${Color.Purple};
            font-size: ${FontSize.body}px;
            margin-left: 40px;
          }

          & .add-others-button {
            cursor: pointer;
            margin-left: 5px;
            color: ${Color.Purple};
            font-size: ${FontSize.body}px;
          }

          & .option-drag-block {
            width: 40px;

            & .option-drag-icon {
              display: none;
              color: rgba(15, 14, 35, 0.4);

              &.focus {
                display: block;
                color: #837ae8;
              }
            }
          }
        }
      }
    }
  }

  .helpText {
    height: 22px;
    color: red;
  }
  .total-tip {
    cursor: default;
    .blue {
    }

    .red {
      color: red;
    }
  }
`;

const DisplayImageWrapper = styled.div`
  position: relative;
  display: inline-block;

  & > .mask {
    position: absolute;
    top: 0px;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.5s ease-in;

    &:hover {
      opacity: 1;
      & > .action-items {
        pointer-events: all;
      }
    }

    & > .action-items {
      pointer-events: none;
    }

    & > .action-items {
      display: flex;

      & > .action-item:first-child {
        margin-right: 10px;
      }
    }
  }

  ${props => props.extraCss || ""}
`;

const DragWrapper = styled.div`
  ${props =>
    props.isDragging &&
    `
    border: 1px solid #837ae8;
    background-color: white;
    box-shadow: 1px ${Color.Purple};
  `}
`;

export { defaultOption as numericalDefaultOption };
export default Comp;
