import React, { useState, useCallback } from "react";
import * as Ant from "antd";
import styled from "styled-components";
import Widget, { Font, Color } from "./Widget";
import * as SvgIcon from "./SvgIcon";
import { Image } from "styled-icons/material";
import { ContainerOutlined } from "@ant-design/icons";
import CropImage from "./CropImage";
import getCroppedImg from "./CropImage.crop";
import imageCompression from "browser-image-compression";

function byteCount(s) {
  return encodeURI(s).split(/%..|./).length - 1;
}

function getBase64(file) {
  if (!file) return;
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

async function compressFile(file, width) {
  if (!file || !width) return;
  if (file.size < 3 * 100 * 1000) return file;
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: width,
    useWebWorker: true,
  };

  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    // console.log(error);
  }
}
export default function AddImageModal(props) {
  const [imageLink, setImageLink] = useState();
  const [uploadedFile, setUploadedFile] = useState(
    props.selectedImgUrl === undefined || props.selectedImgUrl === ""
      ? null
      : { preview: props.selectedImgUrl }
  );
  const [isUpload, setIsUpload] = useState(false);
  const [type, setType] = useState("upload");
  const [showMask, setShowMask] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageWidth, setImageWidth] = useState(1920);
  async function onOk() {
    if (type === "link") {
      if (!imageLink || (imageLink && imageLink.indexOf("http") === -1)) {
        return;
      }
      props.insertImage(imageLink);
    } else {
      if (!uploadedFile) {
        return;
      }
      const result = await getResultCroppedImage();
      if (byteCount(result) > 2 * 1000 * 1000) {
        Ant.message.error("檔案限制為 2MB！");
        return;
      }

      props.insertImage(result);
    }
    setImageLink("");
    setUploadedFile(null);
    props.onCancel();
  }

  const getResultCroppedImage = useCallback(async () => {
    const rotation = 0;
    try {
      const croppedImage = await getCroppedImg(
        uploadedFile.preview,
        croppedAreaPixels,
        rotation,
        uploadedFile.type
      );
      return croppedImage;
    } catch (e) {
      // console.warn(e);
    }
  }, [croppedAreaPixels, uploadedFile]);

  const _uploadProps = {
    name: "file",
    multiple: false,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    showUploadList: false,
    async onChange(info) {
      //const { status } = info.file;
      // if (status !== "uploading") {
      //   console.log(info.file, info.fileList);
      // }
      // if (status === "done") {
      //   Ant.message.success(`${info.file.name} file uploaded successfully.`);
      // } else if (status === "error") {
      //   Ant.message.error(`${info.file.name} file upload failed.`);
      // }
      let updatedFile = {
        ...info.fileList[info.fileList.length - 1],
      };

      if (
        updatedFile.originFileObj &&
        updatedFile.originFileObj.size / 1024 / 1024 < 2
      ) {
        switch (updatedFile.type) {
          case "image/gif":
            updatedFile.preview = await getBase64(updatedFile.originFileObj);
            break;
          case "image/jpeg":
          case "image/png":
            let comfile = await compressFile(
              updatedFile.originFileObj,
              imageWidth
            );
            updatedFile.preview = await getBase64(comfile);
            break;
          default:
          //type wrong do nothing
        }
      }
      if (updatedFile.preview) {
        setUploadedFile(updatedFile);
        setType("upload");
      }
    },
  };

  const _beforeUpload = file => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", event => {
      const _loadedImageUrl = event.target.result;
      const image = document.createElement("img");
      image.src = _loadedImageUrl;
      image.addEventListener("load", () => {
        const { width } = image;
        setImageWidth(width);
      });
    });
    setIsUpload(true);
    const isLt2M = file.size / 1024 / 1024 < 2;
    const isCorrectType =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif";

    if (!isCorrectType || !isLt2M) {
      Ant.message.error("僅支援 jpg、png、gif 格式，且檔案限制為 2 MB");
    }

    return isLt2M && isCorrectType;
  };

  const _onDelete = () => {
    setUploadedFile(null);
  };

  return (
    <Ant.Modal
      title="新增圖片"
      className="custom-modal"
      visible={props.visible}
      bodyStyle={{ padding: 0, margin: 0 }}
      onCancel={props.onCancel}
      footer={null}
      width={560}
    >
      <div
        style={{
          padding: "20px 32px 32px",
          backgroundColor: "#f4f4f4",
        }}
      >
        <Ant.Radio.Group
          defaultValue="link"
          value={type}
          style={{ display: "block" }}
        >
          <Ant.Radio
            style={{ display: "block" }}
            value="upload"
            onClick={() => setType("upload")}
          >
            上傳檔案
            <Widget.FlexCenter style={{ marginTop: 10 }}>
              {uploadedFile ? (
                <DisplayImageWrapper
                  showMask={showMask}
                  onMouseLeave={() => setShowMask(false)}
                  onMouseEnter={() => {
                    setShowMask(true);
                    setTimeout(() => setShowMask(false), 1500);
                  }}
                  style={{ width: 334, height: 250 }}
                >
                  <div className="mask">
                    <div className="action-items">
                      {/* <div className="action-item">
                        <Ant.Upload
                          {..._uploadProps}
                          fileList={[]}
                          beforeUpload={_beforeUpload}
                        >
                          <SvgIcon.EditMode color={Color.GreyWhite} size={30} />
                        </Ant.Upload>
                      </div> */}
                      <div className="action-item" onClick={_onDelete}>
                        <SvgIcon.Delete color={Color.GreyWhite} size={30} />
                      </div>
                    </div>
                  </div>
                  <CropImage
                    className={!isUpload ? "bar-none" : ""}
                    image={uploadedFile.preview ? uploadedFile.preview : ""}
                    imageType={uploadedFile.type}
                    setCroppedAreaPixels={setCroppedAreaPixels}
                  />
                </DisplayImageWrapper>
              ) : (
                <Ant.Upload.Dragger
                  {..._uploadProps}
                  beforeUpload={_beforeUpload}
                  style={{
                    width: 334,
                    paddingTop: 38,
                    paddingBottom: 42,
                    paddingRight: 0,
                    paddingLeft: 0,
                    backgroundColor: "white",
                  }}
                >
                  <div style={{ width: 48, height: 48, margin: "auto" }}>
                    <ContainerOutlined
                      style={{ fontSize: 42, color: Color.Purple }}
                    />
                  </div>
                  <Font.Head
                    style={{ marginTop: 16, marginBottom: 4, height: "24px" }}
                  >
                    將圖片拖曳進來
                  </Font.Head>
                  <Font.Body style={{ color: Color.GreyBlack_45 }}>
                    支援檔案格式：jpg、png、gif
                    <br />
                    單一檔案最高為 2MB
                  </Font.Body>
                </Ant.Upload.Dragger>
              )}
            </Widget.FlexCenter>
          </Ant.Radio>

          <Widget.Line style={{ marginTop: 19, marginBottom: 19 }} />

          <Ant.Radio
            style={{
              display: "block",
              height: 22,
              lineHeight: "22px",
              marginBottom: 8,
            }}
            value="link"
            onClick={() => setType("link")}
          >
            輸入圖片連結
          </Ant.Radio>
          <Ant.Input
            value={imageLink}
            onChange={e => {
              setType("link");
              setImageLink(e.target.value);
            }}
            style={{ display: "block", width: "100%", marginTop: 8 }}
            placeholder={"https://"}
          />
          {imageLink && imageLink.indexOf("http") === -1 && (
            <Font.Small style={{ color: Color.LightRed_5, marginTop: 3 }}>
              此網址格式不正確
            </Font.Small>
          )}
        </Ant.Radio.Group>
      </div>

      <Widget.FlexRow
        style={{
          backgroundColor: "white",
          padding: "10px 16.5px",
          justifyContent: "flex-end",
        }}
      >
        <Ant.Button
          style={{ marginRight: 7.5, width: 65 }}
          onClick={props.onCancel}
        >
          取消
        </Ant.Button>
        <Ant.Button style={{ width: 65 }} type="primary" onClick={onOk}>
          確認
        </Ant.Button>
      </Widget.FlexRow>
    </Ant.Modal>
  );
}

const DisplayImageWrapper = styled.div`
  position: relative;
  & > .mask {
    position: absolute;
    top: 0px;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    ${props =>
      props.showMask ? "opacity: 1;" : "opacity: 0;pointer-events: none;"}
    transition: opacity .5s ease-in;

    & > .action-items {
      display: flex;

      & > .action-item:first-child {
        margin-right: 10px;
      }
    }
  }
`;
