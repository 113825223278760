const defaultSurveyConfig = {
  pageIdxDisplay: true,
  questionIdxDisplay: true,
  asteriskDisplay: true,
  progressDisplay: true,
  progressDisplayDetail: {
    position: "bottom", // "bottom" || "top"
    display: "percentage", // "percentage" || "questions"
  },
  validationCode: true,
  oneQuestionPerPage: false,
  endingDisplay: true,
  themeConfig: {
    backgroundColor: "#fff",
    headerImg: "",
    mobileHeaderImg: "",
    styleType: "default", // "default" || "customized"
  },
};

const surveys = [
  {
    id: "1",
    title: "Hinet 非固定制退租客戶關懷調查 2020Q1",
    status: "1",
    audit: false,
    isBlank: false,
    responseCount: null,
    author: "四字測試",
    startDate: "2021-10-07T08:04:21.4497444",
    endDate: "2021-12-07T08:04:21.4497444",
    modified: "2020-09-07T08:04:21.4497444",
    ...defaultSurveyConfig,
    questions: [
      { id: "0", type: "PAGE" },
      {
        id: "1",
        type: "SINGLE_CHOICE",
        title: "Test title",
        content: [
          {
            type: "paragraph",
            children: [{ text: "單選題題目" }],
          },
        ],
        contentVideo: "https://www.youtube.com/embed/Rc9MebQeCkY",
        contentImage:
          "https://pbs.twimg.com/profile_images/1293217550050799617/8LFWTYD3.jpg",
        description: "Test description",
        options: [
          {
            value: "是(包含承租的住家)",
            mediaType: "image",
            url:
              "https://pbs.twimg.com/profile_images/1293217550050799617/8LFWTYD3.jpg",
          },
          {
            value:
              "否，非住家(如：公司、店鋪、工作室、餐廳、工廠、學校、魚塭、出租用等)",
            mediaType: "video",
            url: "https://www.youtube.com/embed/Rc9MebQeCkY",
          },
          {
            value: "第三種選擇",
            mediaType: "image",
            url:
              "https://pbs.twimg.com/profile_images/1293217550050799617/8LFWTYD3.jpg",
          },
          {
            value: "第四種選擇",
          },
          {
            value: "其他",
            type: "others",
            data: {
              errorMsg: "錯誤！",
              inputCharacter: 50,
              inputLine: "1",
              isMandatory: true,
              validateType: "length",
            },
          },
        ],
        required: true,
        advanced: {
          display: null, // {desktop: 1, mobile: 1}
          random: null, // {excludeOthers: true}
        },
      },
      {
        id: "2",
        type: "MULTIPLE_CHOICE",
        content: [
          {
            type: "paragraph",
            children: [{ text: "多選題題目" }],
          },
        ],
        title: "Test title",
        description: "Test description",
        options: [
          {
            value: "選項一",
            mediaType: "image",
            url:
              "https://pbs.twimg.com/profile_images/1293217550050799617/8LFWTYD3.jpg",
          },
          {
            value: "選項二",
            mediaType: "video",
            url: "https://www.youtube.com/embed/Rc9MebQeCkY",
          },
          { value: "選項三" },
          { value: "選項4" },
          { value: "選項5" },
        ],
        required: true,
        advanced: {
          limit: { from: 3, to: 8 }, // 選項數量限制 {from: 2, to: 8}
          display: null, // {desktop: 1, mobile: 1}
          random: null, // {excludeOthers: true}
        },
      },
      {
        id: "3",
        type: "SINGLE_CHOICE",
        content: [
          {
            type: "paragraph",
            children: [{ text: "另一個單選題題目" }],
          },
        ],
        title: "Test title",
        description: "Test description",
        options: [
          {
            value: "aaa",
          },
          {
            value: "bbb",
          },
          { value: "ccc" },
          { value: "ooo", type: "others", data: null },
          { value: "ddd" },
        ],
        advanced: {
          display: { desktop: 4, mobile: 1 }, // {desktop: 1, mobile: 1}
          random: { excludeOthers: true }, // {excludeOthers: true}
        },
      },
      {
        id: "4",
        type: "FILL_IN",
        content: [
          {
            type: "paragraph",
            children: [{ text: "請問你家裡居住幾個人？" }],
          },
        ],
        title: "Test title",
        description: "Test description",
        required: false,
        answerPlaceholder: null, // "" 才可編輯
        validateAnswer: { type: "number" }, // none | text | number
      },
      { id: "4.1", type: "PAGE" },
      {
        id: "5",
        type: "MATRIX",
        rows: ["欄位一", "欄位二", "欄位三", "欄位四"],
        cols: ["差", "普通", "讚"],
        content: [
          {
            type: "paragraph",
            children: [{ text: "請問您平日從事以下影視娛樂使用行為的頻率？" }],
          },
        ],
        title: "Test title",
        description: "Test description",
        required: true,
      },
      {
        id: "6",
        type: "BASIC_INFO",
        content: [
          {
            type: "paragraph",
            children: [{ text: "請輸入您的身分證字號" }],
          },
        ],
        infoType: "id",
      },
      {
        id: "7",
        type: "TEXT",
        content: [
          {
            type: "paragraph",
            children: [{ text: "感謝您！" }],
          },
        ],
      },
      {
        id: "8",
        type: "IMAGE",
        content: [],
        url:
          "https://pbs.twimg.com/profile_images/1293217550050799617/8LFWTYD3.jpg",
      },
      {
        id: "9",
        type: "VIDEO",
        content: [],
        url: "https://www.youtube.com/embed/Rc9MebQeCkY",
      },
    ],
  },
  {
    id: "2",
    title: "Survey 2",
    status: "2",
    audit: false,
    responseCount: 0,
    author: "David",
    startDate: "2021-10-07T08:04:21.4497444",
    endDate: "2021-12-07T08:04:21.4497444",
    modified: "2020-09-07T08:04:21.4497444",
    questions: [],
    ...defaultSurveyConfig,
  },
  {
    id: "3",
    title: "Survey 3",
    status: "3",
    audit: false,
    responseCount: 300,
    author: "Jackson",
    startDate: "2021-10-07T08:04:21.4497444",
    endDate: "2021-12-07T08:04:21.4497444",
    modified: "2020-09-07T08:04:21.4497444",
    questions: [],
    ...defaultSurveyConfig,
  },
  {
    id: "4",
    title: "Survey 4",
    status: "4",
    audit: true,
    responseCount: null,
    author: "David",
    startDate: "2021-10-07T08:04:21.4497444",
    endDate: "2021-12-07T08:04:21.4497444",
    modified: "2020-09-07T08:04:21.4497444",
    questions: [],
    ...defaultSurveyConfig,
  },
  {
    id: "6",
    title: "Survey 6",
    status: "1",
    audit: false,
    responseCount: null,
    author: "David",
    startDate: "2021-10-07T08:04:21.4497444",
    endDate: "2021-12-07T08:04:21.4497444",
    modified: "2020-09-07T08:04:21.4497444",
    questions: [],
    ...defaultSurveyConfig,
  },
  {
    id: "7",
    title: "Survey 7",
    status: "1",
    audit: false,
    responseCount: 0,
    author: "David",
    startDate: "2021-10-07T08:04:21.4497444",
    endDate: "2021-12-07T08:04:21.4497444",
    modified: "2020-09-07T08:04:21.4497444",
    questions: [],
    ...defaultSurveyConfig,
  },
  {
    id: "8",
    title: "Survey 8",
    status: "1",
    audit: false,
    responseCount: 300,
    author: "Jackson",
    startDate: "2021-10-07T08:04:21.4497444",
    endDate: "2021-12-07T08:04:21.4497444",
    modified: "2020-09-07T08:04:21.4497444",
    questions: [],
    ...defaultSurveyConfig,
  },
  {
    id: "9",
    title: "Survey 9 (Blank)",
    status: "1",
    audit: false,
    responseCount: null,
    author: "David",
    startDate: "2021-10-07T08:04:21.4497444",
    endDate: "2021-12-07T08:04:21.4497444",
    modified: "2020-09-07T08:04:21.4497444",
    isBlank: true,
    questions: [],
    ...defaultSurveyConfig,
  },
];

const surveyLogicList = [];

const surveyCollectionList = [
  // {
  //   surveyId: "1",
  //   list: [
  //     {
  //       id: "1",
  //       method: "website",
  //       created: "2020/03/28 13:22",
  //       updated: "2020/04/28 13:22",
  //       respCnt: 0,
  //       upperBound: 1000,
  //       accessList: [],
  //       liveUrl: '',
  //       testUrl: '',
  //       validateField: "cht", // cht | hn | md | tel | tel-2 | phone | email | secondary-email | facebook-id | serial-number | device-id | ex-1 | ex-2
  //       duplicateMechanism: 'repeat', // overwrite | first-only | repeat
  //       loginType: 'anonymous' // anonymous | validation | parameter
  //     },
  //     {
  //       id: "2",
  //       method: "qrcode",
  //       created: "2020/03/29 13:22",
  //       updated: "2020/05/18 13:22",
  //       respCnt: 0,
  //       upperBound: 10000,
  //       accessList: [],
  //       liveUrl: '',
  //       testUrl: '',
  //       validateField: "cht",
  //       duplicateMechanism: "repeat",
  //       loginType: 'anonymous'
  //     },
  //   ]
  // }
];

const statisticsData = {
  // note: only mimic first survey
  // totalRespCount: 4566,
  // live: {
  //   questions: [
  //   { // 單選、多選
  //     respCount: 100,
  //     optionStats: [{...q.option, respCount: 23}]
  // }, { // 矩陣
  //   respCount: 120,
  //   cols: ['滿意', '還好', '不滿意'],
  //   optionMatrixStats: [{
  //     label: '欄位一',
  //     respCounts: [23, 10, 5],
  //   }, {
  //     label: '欄位二',
  //     respCounts: [20, 15, 6],
  //   }
  // ]
  // }
  // ]
  //// q.
  // },
  // test: {
  //   questions: surveys[0].questions.filter(q => q.type !== "PAGE").map(q => ({...q, respCount: 22}))
  // }
};

const userList = [
  {
    id: "1",
    name: "David",
    username: "david_liao",
    role: "admin",
    RoleId: "1",
    created: "2020/03/28 13:22",
    lastLoginAt: "2020/04/28 13:22",
  },
  {
    id: "2",
    name: "James",
    username: "james_bond",
    role: "staff",
    RoleId: "2",
    created: "2020/03/28 13:22",
    lastLoginAt: "2020/04/28 13:22",
  },
  {
    id: "3",
    name: "Jordan",
    username: "jordan_fian",
    role: "staff",
    RoleId: "2",
    created: "2020/03/28 13:22",
    lastLoginAt: "2020/04/28 13:22",
  },
];

const templateList = [
  {
    id: "1000001",
    title: "HiNet企業上網固定制退租客戶調查2019Ｑ2-1",
    questionCount: 30,
    survey: surveys[0], // template data
  },
  {
    id: "1000002",
    title: "HiNet企業上網固定制退租客戶調查2019Ｑ2-1",
    questionCount: 30,
    survey: surveys[0],
  },
  {
    id: "1000003",
    title: "HiNet企業上網固定制退租客戶調查2019Ｑ2-1",
    questionCount: 30,
    survey: surveys[0],
  },
  {
    id: "1000004",
    title: "HiNet企業上網固定制退租客戶調查2019Ｑ2-1",
    questionCount: 30,
    survey: surveys[0],
  },
  {
    id: "1000005",
    title: "HiNet企業上網固定制退租客戶調查2019Ｑ2-1",
    questionCount: 30,
    survey: surveys[0],
  },
];

export {
  surveys,
  surveyLogicList,
  surveyCollectionList,
  statisticsData,
  userList,
  templateList,
};
