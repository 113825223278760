import React, { useState } from "react";
import * as Ant from "antd";
import RichTextEditor, { clearAllFormats } from "./RichTextEditor";
import Widget, { Color } from "./Widget";

export default function AdvEditingModal(props) {
  const [snapshot, setSnapshot] = useState(props.editor.children);

  return (
    <Ant.Modal
      className="custom-modal"
      title="題目進階編輯"
      visible={true}
      bodyStyle={{ padding: 20, backgroundColor: "#efefef" }}
      onCancel={() => {
        // reset editor to previous
        // we have to manually handle focus when force setting content,
        // see https://github.com/ianstormtaylor/slate/issues/3477
        props.editor.selection = {
          anchor: { path: [0, 0], offset: 0 },
          focus: { path: [0, 0], offset: 0 },
        };
        props.editorProps?.setContent(snapshot);
        props.onCancel();
      }}
      closable={true}
      footer={[
        <Ant.Button
          key="back"
          onClick={() => {
            try {
              clearAllFormats(
                props.editor.children,
                props.editorProps.setContent
              );
            } catch (err) {
              // console.log("clear format error:", err);
            }
          }}
          style={{
            color: Color.Purple,
            borderColor: Color.Purple,
          }}
        >
          清除樣式
        </Ant.Button>,
        <Ant.Button
          key="submit"
          type="primary"
          onClick={() => {
            props.onCancel(); // note: actually it is onClose
          }}
        >
          確定
        </Ant.Button>,
      ]}
    >
      <RichTextEditor editor={props.editor} styles={{ minHeight: 235 }} />

      <Widget.WarnLabel style={{ marginTop: 8 }}>
        系統預設標題為思源黑體粗體，字級 20 pt
      </Widget.WarnLabel>
    </Ant.Modal>
  );
}
