import React, { useState } from "react";
import styled from "styled-components";
import * as Ant from "antd";
import _ from "lodash";
import * as Icon from "./Icon";
import Widget, { Font, Color, FontSize } from "./Widget";

const DefaultOtherOptionConfig = {
  errorMsg: "您輸入的意見為無效格式",
  otherIsShowText: false,
  validateType: "none",
  from: 1,
  to: 100,
  inputLine: "1",
  inputCharacter: 50,
  useOtherText: false,
  otherText: "",
  isMandatory: false,
};

const AddOtherModal = ({ visible, onOK, onCancel, ...props }) => {
  const [data, setData] = useState(
    props.otherOption ? { ...props.otherOption.data } : DefaultOtherOptionConfig
  );
  const [min, setMin] = useState(1);
  const [max, setMax] = useState(300);

  return (
    <Ant.Modal
      className="custom-modal"
      title="「其他」選項細節設定"
      visible={visible}
      okText="確定"
      cancelText="取消"
      onOk={() => {
        console.debug("kkkkk", _.get(data, "otherText.length"));
        if (_.get(data, "otherText.length", 0) > 500) {
          alert("其他說明文字最大長度為500");
          return;
        }
        onOK("其他", data);
      }}
      onCancel={onCancel}
      bodyStyle={{ padding: 0 }}
      width={560}
    >
      <Content>
        {/* {_.get(data, "otherText")} */}
        <div className="preview">
          <Font.Body style={{ marginBottom: 11 }}>預覽</Font.Body>
          <Font.Body
            style={{
              marginLeft: 40,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: 8 }}>
              其他
              {/* 2022-05-18: 新增"其他"文字 */}
              {data.useOtherText ? data.otherText : ""}
            </div>
            {data.otherIsShowText && (
              <Widget.FlexRow>
                <PreviewInputWrapper isRequired={data.isMandatory}>
                  <input
                    placeholder="請輸入"
                    className="option-input-others"
                    value=""
                    onChange={() => 0}
                  />
                </PreviewInputWrapper>
              </Widget.FlexRow>
            )}
          </Font.Body>
        </div>
        <div className="detail">
          <Widget.FlexRow style={{ marginBottom: 8 }}>
            {data.useOtherText ? (
              <Icon.CheckBox
                size={FontSize.head}
                color={Color.Purple}
                style={{ marginRight: 8 }}
                onClick={() => setData({ ...data, useOtherText: false })}
              />
            ) : (
              <Icon.CheckBoxOutlineBlank
                size={FontSize.head}
                color={Color.GreyBlack_25}
                style={{ marginRight: 8 }}
                onClick={() => setData({ ...data, useOtherText: true })}
              />
            )}
            <Font.Body style={{ color: Color.GreyBlack }}>
              在「其他」之後增加說明文字
            </Font.Body>
          </Widget.FlexRow>
          <div className="sub-config" style={{ marginBottom: 32 }}>
            <input
              disabled={!data.useOtherText}
              style={{
                width: "100%",
                color: data.useOtherText
                  ? "rgba(0, 0, 0, 0.65)"
                  : "rgba(0, 0, 0, 0.45)",
              }}
              placeholder={""}
              className="option-input-others"
              value={data.otherText}
              onChange={e => setData({ ...data, otherText: e.target.value })}
            />
          </div>
          <Widget.FlexRow style={{ marginBottom: 16 }}>
            {data.otherIsShowText ? (
              <Icon.CheckBox
                size={FontSize.head}
                color={Color.Purple}
                style={{ marginRight: 8 }}
                onClick={() =>
                  setData(state => ({
                    ...DefaultOtherOptionConfig,
                    useOtherText: state.useOtherText,
                    otherText: state.otherText,
                  }))
                }
              />
            ) : (
              <Icon.CheckBoxOutlineBlank
                size={FontSize.head}
                color={Color.GreyBlack_25}
                style={{ marginRight: 8 }}
                onClick={() => setData({ ...data, otherIsShowText: true })}
              />
            )}
            <Font.Body style={{ color: Color.GreyBlack }}>
              後面添加文字輸入欄位
            </Font.Body>
          </Widget.FlexRow>
          <div className="sub-config">
            <Font.Body style={{ marginBottom: 8, color: Color.GreyBlack }}>
              驗證文字內容
            </Font.Body>
            <Widget.FlexRow style={{ marginBottom: 27 }}>
              <Ant.Select
                disabled={!data || !data.otherIsShowText}
                defaultValue="none"
                value={data?.validateType || "none"}
                onChange={v => {
                  setData({
                    ...data,
                    validateType: v,
                    errorMsg:
                      v === "number" ? "須為整數" : "您輸入的意見為無效格式",
                    from: v === "number" ? 0 : 1,
                  });
                  setMin(v === "number" ? 0 : 1);
                  setMax(v === "number" ? 100000 : 300);
                }}
              >
                <Ant.Select.Option value="none">
                  請勿驗證此答案
                </Ant.Select.Option>
                <Ant.Select.Option value="number">確認為整數</Ant.Select.Option>
                <Ant.Select.Option value="length">
                  確認文字字數
                </Ant.Select.Option>
              </Ant.Select>
              {!data || data.validateType === "none" ? (
                ""
              ) : (
                <>
                  <Font.Body
                    style={{ color: Color.GreyBlack, margin: "0 10px" }}
                  >
                    在
                  </Font.Body>
                  <Ant.InputNumber
                    min={min}
                    max={data?.to}
                    defaultValue={min}
                    value={data?.from || min}
                    style={{ width: "64px" }}
                    onChange={v => {
                      setData({
                        ...data,
                        from: v,
                      });
                    }}
                  ></Ant.InputNumber>
                  <Font.Body
                    style={{ color: Color.GreyBlack, margin: "0 10px" }}
                  >
                    和
                  </Font.Body>
                  <Ant.InputNumber
                    min={data?.from}
                    max={max}
                    defaultValue={100}
                    value={data?.to || 100}
                    style={{ width: "84px" }}
                    onChange={v => {
                      setData({
                        ...data,
                        to:
                          v <= data.validateType === "number"
                            ? 100000
                            : 300
                            ? v
                            : data.validateType === "number"
                            ? 100000
                            : 300,
                      });
                    }}
                  ></Ant.InputNumber>
                  <Font.Body
                    style={{ color: Color.GreyBlack, margin: "0 10px" }}
                  >
                    {data.validateType === "number" ? "數值之間" : "個字數之間"}
                  </Font.Body>
                </>
              )}
            </Widget.FlexRow>
            <Widget.FlexRow style={{ marginBottom: 11 }}>
              {data?.isMandatory ? (
                <Icon.CheckBox
                  size={FontSize.head}
                  color={Color.Purple}
                  style={{ marginRight: 8 }}
                  onClick={() =>
                    data && setData({ ...data, isMandatory: false })
                  }
                />
              ) : (
                <Icon.CheckBoxOutlineBlank
                  size={FontSize.head}
                  color={Color.GreyBlack_25}
                  style={{ marginRight: 8 }}
                  onClick={() =>
                    data &&
                    setData({
                      ...data,
                      isMandatory: true && data?.otherIsShowText,
                    })
                  }
                />
              )}
              <Font.Body style={{ color: Color.GreyBlack }}>
                設定為必填欄位
              </Font.Body>
            </Widget.FlexRow>
            <Font.Body
              style={{ marginBottom: 4, color: "rgba(0, 0, 0, 0.25)" }}
            >
              當答案未填寫時，顯示此錯誤訊息
            </Font.Body>
            <input
              disabled={!data || !data.isMandatory}
              style={{
                width: "100%",
                color: data.isMandatory
                  ? "rgba(0, 0, 0, 0.65)"
                  : "rgba(0, 0, 0, 0.25)",
              }}
              placeholder={""}
              className="option-input-others"
              value={data.errorMsg}
              onChange={e => setData({ ...data, errorMsg: e.target.value })}
            />
          </div>
        </div>
      </Content>
    </Ant.Modal>
  );
};

const Content = styled.div`
  & > .preview {
    padding: 11px 67px 24px 28px;
  }

  & > .detail {
    background-color: #f4f4f4;
    padding: 16px 51px 32px 28px;

    & .sub-config {
      padding: 0px 0px 0px 24px;
    }
  }

  & input.option-input-others {
    border: 1px solid ${Color.GreyBlack_15};
    padding-left: 12px;
    flex-basis: 160px;
    font-size: ${FontSize.body}px;
    flex-grow: 0;
    flex-shrink: 0;
    height: 32px;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    ::placeholder {
      color: rgba(0, 0, 0, 0.25);
    }
  }
`;

const PreviewInputWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-left: ${props => (props.isRequired ? "14px" : "0")};

  &::before {
    display: ${props => (props.isRequired ? "block" : "none")};
    content: "*";
    color: ${Color.Red};
    font-size: ${FontSize.body}px;
    position: absolute;
    top: 0;
    left: 0;
  }

  > .option-input-others {
    width: 100%;
    flex-basis: auto;
  }
`;

export default AddOtherModal;
