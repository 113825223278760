const ApiProxyFactory = require("./ApiProxy");
const constants = require("./constants");

const ApiProxy = new ApiProxyFactory({ apiUrl: constants.apiUrl });

module.exports = {
  getToken: () => {
    return ApiProxy.token;
  },

  setToken: token => {
    ApiProxy.setToken(token);
  },

  echo: async msg => {
    return msg;
  },
};
