import React from "react";
import Widget, { Color } from "./Widget";

export default function QuestionBottomBar({
  type,
  onCheck,
  onCancel,
  onSave,
  onClean,
  required,
  ...props
}) {
  return (
    <div className="question-config">
      {(() => {
        switch (type) {
          case "VIDEO":
          case "TEXT":
          case "IMAGE":
            return null;
          default:
            return (
              <div className="toggle-required-wrapper">
                必填{" "}
                <Widget.Switch
                  style={{ marginLeft: 14 }}
                  checked={required}
                  onChange={checked => onCheck(checked)}
                />{" "}
              </div>
            );
        }
      })()}
      <div style={{ flex: 1 }} />
      {(() => {
        switch (type) {
          case "TEXT":
            return (
              <Widget.Button
                onClick={onClean}
                style={{
                  borderColor: Color.Purple,
                  color: Color.Purple,
                  marginRight: 24,
                }}
              >
                清除樣式
              </Widget.Button>
            );
        }
      })()}
      <Widget.Button
        onClick={onCancel}
        style={{ borderColor: Color.Purple, color: Color.Purple }}
      >
        取消
      </Widget.Button>
      <Widget.Button
        type={"primary"}
        onClick={onSave}
        style={{ marginLeft: 24 }}
      >
        儲存
      </Widget.Button>
    </div>
  );
}
