let _PERSIST = true;

function enableStorage(enable) {
  _PERSIST = enable;
}

const persistToStorage = (key, ref) => fn => {
  if (!_PERSIST) {
    return fn;
  }

  const context = this;
  async function wrapper() {
    let result = fn.call(context, ...arguments);
    if (result.then === undefined) {
      throw new Error("can only wrap async function");
    }

    result = await result;
    localStorage.setItem(key, JSON.stringify(ref));
    return result;
  }
  return wrapper;
};

function loadFromStorage(key, initialData) {
  if (!_PERSIST) {
    return initialData;
  }

  try {
    const result = localStorage.getItem(key);
    if (result) {
      return JSON.parse(result);
    }
  } catch (ex) {}
  return initialData;
}

export { enableStorage, persistToStorage, loadFromStorage };
