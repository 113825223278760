import React, { useState } from "react";
import { connect } from "react-redux";
import * as Ant from "antd";
import styled from "styled-components";

import { Color } from "../Widget";
import * as Icon from "../Icon";
import { ColorPicker } from "./ColorPicker";
import ActionCreator from "../../ActionCreator";
import * as Survey from "../../Contexts/SurveyContext";
import * as SvgIcon from "../../Components/SvgIcon";

// 每行呈現多少顏色
const coloeNumPerRaw = 10;
// 調色盤預設顏色
const colorPalette = [
  "#000000",
  "#434343",
  "#666666",
  "#999999",
  "#b7b7b7",
  "#cccccc",
  "#d9d9d9",
  "#efefef",
  "#f3f3f3",
  "#ffffff",
  "#ff0000",
  "#f24726",
  "#ff9901",
  "#ffff02",
  "#00ff00",
  "#01ffff",
  "#4a86e8",
  "#000dff",
  "#9900ff",
  "#ff00ff",
  "#f4cccc",
  "#fcdad3",
  "#fce5cd",
  "#fff2cc",
  "#e6f6f3",
  "#cff5f6",
  "#c9daf8",
  "#ecedf7",
  "#d9d2e9",
  "#f7d3df",
  "#ea9999",
  "#f8a392",
  "#f9cb9c",
  "#ffe599",
  "#9edbcf",
  "#89e6e9",
  "#a4c2f4",
  "#c6c9e7",
  "#b4a7d6",
  "#ec91b1",
  "#e06666",
  "#f46b51",
  "#f6b26b",
  "#ffd967",
  "#3db8a0",
  "#2ad2d8",
  "#6d9feb",
  "#666fc1",
  "#8e7cc3",
  "#dd3972",
  "#cc0101",
  "#d93f22",
  "#e69138",
  "#f1c231",
  "#0da789",
  "#11b8be",
  "#3c78d8",
  "#414cb2",
  "#674ea7",
  "#c42059",
  "#990100",
  "#a9311a",
  "#b45f06",
  "#bf9002",
  "#09745f",
  "#0d8f94",
  "#1155cc",
  "#343c8e",
  "#351c75",
  "#82153b",
  "#660000",
  "#792313",
  "#783f04",
  "#7f6000",
  "#054236",
  "#053d3f",
  "#1c4587",
  "#202659",
  "#21134e",
  "#410a1d",
];

const setTickColor = color => {
  return [
    "#000000",
    "#434343",
    "#666666",
    "#999999",
    "#ff0000",
    "#f24726",
    "#ff9901",
    "#000dff",
    "#990100",
    "#a9311a",
    "#b45f06",
    "#bf9002",
    "#09745f",
    "#0d8f94",
    "#1155cc",
    "#343c8e",
    "#351c75",
    "#82153b",
    "#660000",
    "#792313",
    "#783f04",
    "#7f6000",
    "#054236",
    "#053d3f",
    "#1c4587",
    "#202659",
    "#21134e",
    "#410a1d",
  ].includes(color)
    ? "ffffff"
    : "000000";
};

const ColorPalette = props => {
  const [visible, setVisible] = useState(false);
  const { theme, appActions, dispatch, color, selectedColor } = props;

  // 自訂顏色 array
  const {
    ExtendColorPalette: extendColorPalette = [],
    SurveyId: survey_id,
  } = theme;

  const addColor = async color => {
    // 比對調色盤預設顏色，如果尚未存在於調色盤，就新增於自訂
    if (!colorPalette.find(e => e === color)) {
      let colors = [...extendColorPalette];
      colors.push(color);

      await Survey.Actions.updateThemePropertyById(survey_id, {
        ExtendColorPalette: colors.join(","),
      });

      // 因為找不到 mapDispatchToProps 綁不進 props 的原因，只能直接呼叫
      ActionCreator(dispatch).appActions.setTheme({
        ExtendColorPalette: colors,
      });
      // appActions.setTheme({
      //   ExtendColorPalette: colors,
      // });
    }
    setVisible(false);
  };

  return (
    <ColorBox>
      <ColorBlock className="color-block-wrapper">
        {colorPalette.map((item, index) => {
          return (
            <ColorCircle
              key={item}
              color={item}
              size={20}
              style={{ cursor: "pointer" }}
              endOfColumn={(index + 1) % coloeNumPerRaw == 0}
              endOfRow={colorPalette.length - index <= coloeNumPerRaw}
              selected={selectedColor === item}
              numberOfRows={Math.floor(
                (index + coloeNumPerRaw) / coloeNumPerRaw
              )}
              onClick={() => {
                props.setColor(item);
                props.setVisible(false);
              }}
            ></ColorCircle>
          );
        })}
      </ColorBlock>
      <div>自訂</div>
      <ColorBlock>
        {extendColorPalette &&
          extendColorPalette.map(item => (
            <ColorCircle
              key={item}
              color={item}
              size={20}
              style={{ cursor: "pointer" }}
              selected={selectedColor === item}
              onClick={() => {
                props.setColor(item);
                props.setVisible(false);
              }}
            ></ColorCircle>
          ))}
        <Ant.Popover
          content={<ColorPicker setVisible={setVisible} addColor={addColor} />}
          trigger="click"
          visible={visible}
          getPopupContainer={triggerNode => triggerNode.parentNode}
        >
          <ColorCircle
            key="#f0f0f0"
            color="#f0f0f0"
            size={20}
            style={{ cursor: "pointer" }}
            onClick={() => setVisible(true)}
          >
            <Icon.Add size={20} color={Color.GreyBlack_45} />
          </ColorCircle>
        </Ant.Popover>
      </ColorBlock>
    </ColorBox>
  );
};

export default connect(
  (state, ownProps) => ({ theme: state.theme }),
  ActionCreator
)(ColorPalette);

const ColorBox = styled.div`
  width: 250px;
  padding: 12px 16px;
`;

const ColorBlock = styled.div`
  position: relative;
  background-color: ${props => props.color};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 0;

  &:first-child {
    margin: 0 0 8px 0;
  }

  &:last-child {
    margin: 4px 0 0 0;
  }

  & .ant-popover-inner-content {
    padding: 0px !important;
  }
`;

const ColorCircle = styled.div`
  flex: 1;
  min-width: ${props => props.size}px;
  min-height: ${props => props.size}px;
  max-width: ${props => props.size}px;
  max-height: ${props => props.size}px;
  border: ${props =>
    props.numberOfRows === 3
      ? "1px solid #e4e5e8"
      : ["#efefef", "#f3f3f3", "#ffffff"].includes(props.color)
      ? "1px solid #e4e5e8;"
      : "none"};
  border-radius: ${props => props.size / 2}px;
  background-color: ${props => props.color};
  margin-right: ${props => (props.endOfColumn ? "0" : "2")}px;
  margin-bottom: ${props =>
    props.endOfRow ? "0" : [1, 2].includes(props.numberOfRows) ? "5" : "2"}px;
  line-height: 1;
  position: relative;

  & :before {
    ${props => (props.color === "transparent" ? "content: '';" : "")}
    width: 1px;
    display: block;
    height: 40px;
    background-color: #a6a6a6;
    position: absolute;
    transform: rotate(-45deg) translateX(13px);
  }

  &::after {
    display: ${props => (props.selected ? "block" : "none")};
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg version='1.1' width='10px' height='10px' viewBox='0 0 10.0 10.0' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3CclipPath id='i0'%3E%3Cpath d='M9.229,0 L9.958,0.771 L3.208,7.521 L0,4.292 L0.729,3.542 L3.208,6 L9.229,0 Z'%3E%3C/path%3E%3C/clipPath%3E%3C/defs%3E%3Cg transform='translate(2.6911806116913795e-13 0.9999999999998659)'%3E%3Cg transform=''%3E%3Cg clip-path='url(%23i0)'%3E%3Cpolygon points='0,0 9.958,0 9.958,7.521 0,7.521 0,0' stroke='none' fill='%23${props =>
      setTickColor(
        props.color
      )}'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }
`;
